import React, {useState} from 'react';
import Modal from './../Modal';
import _ from 'lodash'

const isObject = obj => obj != null && obj.constructor.name === "Object";

const DirectoryList = ({appProps, floor_plan_image=false}) => {
    let stores = appProps.stores;
    let types = [], categories = [];

    // Filter get retail to display first
    let retail = stores.filter(s => !!s.props && !!s.props.category && s.props.category.toLowerCase().indexOf('retail') > -1)
    stores = retail.concat(stores)
    stores = _.uniqBy(stores, s => s._id)

    stores.forEach( store => {
        if (!!store && !!store.props && !!store.props.type && types.indexOf(store.props.type) === -1) {
            store.props.type != 'Office' && types.push(store.props.type);
        }
        if (!!store && !!store.props && types.indexOf(store.props.category) === -1) {
            categories.push(store.props.category);
        }
    });

    if (floor_plan_image) {
        types.push('Map');
    }

    const [currentType, setCurrentType] = useState(types[0]);
    const [currentStore, setCurrentStore] = useState(false);
    const [search, setSearch] = useState(false);
    const [currentCategory, setCurrentCategory] = useState(false);
    const modalStore = currentStore ? (<article className="modal_store">
        <div className="title-container">
            <figure className="store_image">
                {(!!currentStore.props.thumbnail || !!currentStore.props.featured_image) && <img src={!!currentStore.props.thumbnail ? currentStore.props.thumbnail : currentStore.props.featured_image} alt={currentStore.props.title}/>}
            </figure>
            <h2 className="sp-m-sm title c-background">{currentStore.props.title}</h2>
        </div>
        <div className="modal_store_content">
            <div className="contact-info">
                <p className="sp-m-sm">
                    {currentStore.props.phone} 
                    <br />
                    {currentStore.props.suite}
                    <br />
                    {!!currentStore.props.link && !!currentStore.props.link.url && <a target={currentStore.props.link.target} href={currentStore.props.link.url} download={currentStore.props.link.url.indexOf('.pdf') > -1 ? currentStore.props.link.url : false}>{currentStore.props.link.title || "Website"}</a>}
                </p>
            </div>
            {!!currentStore.props.category && <div className="pillbox sp-m-md">{currentStore.props.category}</div>}
            {!!currentStore.props.content && <div dangerouslySetInnerHTML={{__html: `<p>${currentStore.props.content}</p>`}}></div>}
        </div>
    </article>) : false;

    const filteredStores  = _.sortBy(stores.filter(store => {
        return !!store && !!store.props && !!search ? JSON.stringify(store).indexOf(search) > -1 : !!store && !!store.props && (store.props.type === currentType);
    }), [function (o) {
        return o.props.title
    }]);
    let activeCategories = [];
    filteredStores.forEach( store => {
        if (activeCategories.indexOf(store.props.category) === -1 ){
            activeCategories.push(store.props.category);
        }
    });
    activeCategories = activeCategories.filter( c => !!c );

    return (<section className="container directorycontainer sp-m-xl">
        <div className="directory_filters">
            <ul className="directory_type_switcher">
                {types.map( (type, idx) => <li className={`current-${currentType === type}`} key={idx} onClick={ e => {setCurrentType(type); setSearch(false);} }>{!!type && type.split('_').join(' ')}</li>)}
            </ul>
            <div className="directory_search">
                <div role="group">
                    <input id="search" onChange={ e => {
                        setSearch(e.target.value)
                    } } value={!!search ? search : ''} placeholder="Search stores" type="text" />
                </div>
            </div>
        </div>
        {activeCategories.length > 0 && (<div className="currentTags">
            {activeCategories.map( cat => <div className={`pillbox active-${cat === currentCategory ? 'true' : 'false'}`} onClick={ e => {
                setCurrentCategory(cat === currentCategory ? false : cat);
            } }>{cat}</div>  )}
        </div>)}
        <section className="directory">
            {filteredStores
            .filter( s => (!!activeCategories && activeCategories.length > 0 && !!currentCategory) ? s.props.category == currentCategory : true)
            .map( (store,idx) => !!store.props && <article className="directory_store grid sp-m-xs" key={idx}>
                <div className={`store_content sp-m-md ${currentCategory}`}>
                    {!!store.props.category && <div className={`pillbox`}>{store.props.category}</div>}
                    <h3 className="sp-m-xs">{store.props.title}</h3>
                    <p className="sp-m-sm">{store.props.phone} {!!store.props.phone && <br />} {store.props.suite}</p>
                    {!!store.props.hours && isObject(store.props.hours) && <div className="hours sp-m-xs">
                        {Object.keys(store.props.hours).map((day, i) => {
                            return <div className="day" key={i}>
                                <p>{day}</p>
                                <p>{Object.values(store.props.hours)[i]}</p>
                            </div>
                        })}
                    </div>}
                    {!!store.props.content && <button className="button style-small style-primary" onClick={e => setCurrentStore(store)}>Details</button>}
                </div>
                <figure className={`store_image ${currentCategory} ${currentType}`}>
                    {(!!store.props.thumbnail || !!store.props.featured_image) && <img src={!!store.props.thumbnail ? store.props.thumbnail : store.props.featured_image} alt={store.props.title}/>}
                </figure>
            </article>)}
        </section>
        <Modal show={!!currentStore} closeModal={ (e) => setCurrentStore(false) }>{modalStore}</Modal>
        {currentType==='Map' && <figure className="directorymap sp-p-sm"><img src={floor_plan_image} alt="Floor Plan"/></figure> }
    </section>)
}

export default DirectoryList;