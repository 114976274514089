const orderedPages = (pages) => pages.sort((a, b) => {
    let orderA = a.order || 9999
    let orderB = b.order || 9999
    return Number(orderA) - Number(orderB)
})

const replaceAssetsURL = (url, str="") => {
    return `${url.replace('https://crewcmsblob.blob.core.windows.net/crew', 'https://crewcmsblob.imgix.net')}${str}`
}

export {
    orderedPages,
    replaceAssetsURL
}