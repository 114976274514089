import React, {useState, useEffect} from "react";
import Raw from './Raw';
import { Watch } from 'scrollmonitor-react';

import ComponentEditor from './../coxswain/ComponentEditor';

import Accordion from './Accordion';
import Banner from './Banner';
import ButtonLinks from './ButtonLinks';
import Callout from './Callout';
import ContentMedia from './ContentMedia';
import ContentImage from './ContentImage';
import ContentSwitcher from './ContentSwitcher';
import DirectoryList from './DirectoryList';
import Heading from './Heading/Heading';
import Hero from './Hero';
import Leasing from './Leasing/Leasing';
import IconText from './IconText';
import NewsListing from './NewsListing/NewsListing';
import PageTitle from './PageTitle';
import PremiseCalendar from './PremiseCalendar/PremiseCalendar'
import RichText from './RichText';
import Slider from './Slider/Slider';
import FlipCardList from './FlipCardList/FlipCardList';
import RelatedLinks from './RelatedLinks/RelatedLinks';

import Cookies from 'js-cookie'
import createPath from './../coxswain/apiconstants';
import axios from 'axios';

const CrewModules = {
  Accordion,
  ButtonLinks,
  Banner,
  Callout,
  ContentImage,
  ContentMedia,
  ContentSwitcher,
  DirectoryList,
  Heading,
  Hero,
  Leasing,
  IconText,
  NewsListing,
  PageTitle,
  PremiseCalendar,
  RichText,
  Slider,
  FlipCardList,
  RelatedLinks
}

const ScrollMonitor = Watch(class MyComponent extends React.Component {
	render () {
		return (<div className={`inview-${this.props.isInViewport} fullyinview-${this.props.isFullyInViewport}`}>
			{this.props.children}
		</div>);
	}
});

const ZoneRender = (props) => {
  const {sitemap, flatSitemap, site} = props;
  const currentPage = props.currentPage


  return !!currentPage && !!currentPage.contentZone ? currentPage.contentZone.map( (c, idx) => {
    const Component = CrewModules.hasOwnProperty(c.name) ? CrewModules[c.name] : Raw;
    const _props = {
      count: idx,
      ...c.props,
      name: c.name,
      _id: c._id,
      appProps: props
    }
    const componentProps = {
      pageName:currentPage.pageTitle,
      props: props,
      _props: _props
    }
    return (<React.Fragment key={idx}>
      <a id={`${c.name}-${idx}`}></a>
      <ComponentEditor allowedmodules={props.allowedmodules} componentProps={componentProps} componentIndex={idx} allPageModules={currentPage.contentZone} component={Component} key={idx} />
    </React.Fragment>)
  }) : <div>FETCHING STAGING DATA</div>
}

export default ZoneRender;