import React, {useState} from 'react';
import Close from './../../icons/Close';

const Modal = ({show=false, children, closeModal}) => {
    return show ? (<div className="modal">
        <div className="modal_background" onClick={e => closeModal() }></div>
        <div className="modal_content">
            <div className="modal_close" onClick={e => closeModal() }>
                <Close fill={'background'}/>
            </div>
            {children}
        </div>
    </div>) : false;
}

export default Modal;