import React, {Fragment, useEffect, useState} from 'react';
import Cookies from 'js-cookie'
import './ComponentEditor.scss';
import moduleSchema from './../../component_schema';
import axios from 'axios';
import createPaths from './apiconstants';
import ComponentForm from './ComponentForm';
import AddModule from './coxswainviews/AddModule';
import ComponentFormX from './ComponentFormX';
import { Watch } from 'scrollmonitor-react';
import _ from 'lodash';

const ScrollMonitor = Watch(class MyComponent extends React.Component {
	render () {
		return (<div className={`inview-${this.props.isInViewport} fullyinview-${this.props.isFullyInViewport}`}>
			{this.props.children}
		</div>);
	}
});

const ComponentEditor = (props) => {
  const propertyId = props.componentProps.props.site.parent._id;
  const siteId = props.componentProps.props.site._id;
  const pageId = props.componentProps.props.currentPage._id;
  const componentId = props.componentProps._props._id;
  // console.log(propertyId, siteId, pageId, componentId);
  // const editorpath = createPaths(propertyId, siteId, pageId, componentId).updateModule;
  let editableProps = _.cloneDeep(props.componentProps._props);
  delete editableProps.appProps;
  const [canEdit, setCanEdit] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [isCreateForm, setIsCreateForm] = useState(false)
  const [componentProps, setComponentProps] = useState(editableProps);
  const [isSaving, setIsSaving] = useState(false);
  const [addModule, setAddModule] = useState(false);

  const componentSchema = moduleSchema[editableProps.name];
  useEffect(() => {
    setCanEdit(!!Cookies.get('CREWAUTH'));
  }, []);
  
  const setEditorProps = (newProps) => {
    setComponentProps(newProps);
  }
  const saveData = (data) => {
    setIsSaving(true);
    console.log(data);
    // axios.post('')
    // .then((response) => {
    //   setComponentProps(response.data);
    //   setIsSaving(false);
    // })
    // .catch((error) => {
    //   console.log(error);
    //   alert('Something went wrong. Please contact your CREW adminstrator');
    //   setIsSaving(false);
    // })
  }

  const revealEditForm = (e, toggle=true) => {
    e.preventDefault();
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('coxswain-locked');
    setIsCreateForm(false)
    setShowEditForm(toggle);
  }

  const revealCreateForm = (e, toggle = true) => {
    e.preventDefault();
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('coxswain-locked');
    setIsCreateForm(true)
    setShowEditForm(toggle);
  }

  const onPropChange = (props) =>{
    setComponentProps({
      ..._.cloneDeep(componentProps),
      ...props
    }); 
  }

  const handleOnSubmit = (props, _id, name, isCreate) => {
    console.log('handling submit', props, _id, name, isCreate);

    setIsSaving(true);
    const contentZone = ___appProps.currentPage.contentZone || null
    const currentPageID = ___appProps.currentPage._id || null
    const position = !!contentZone ? _.findIndex(contentZone, c => c._id == _id) : 9999;
    const path = !!isCreate ? 
      createPaths(___appProps.site.parent._id, ___appProps.site._id, currentPageID).createModule : 
      createPaths(___appProps.site.parent._id, ___appProps.site._id, currentPageID, _id).updateModule
    const data = !!isCreate ? {
      name, 
      props, 
      position: position + 1
    } : { props }

    axios({
      method: !!isCreate ? 'POST' : 'PUT',
      url: path,
      headers: {
        "Authorization": `Bearer ${Cookies.get('CREWAUTH')}`
      },
      data: data
    }).then(res => {
      console.log({res, componentProps})
      setShowEditForm(false)
      setIsSaving(false);
      window.location.reload()
    }).catch(err => {
      console.log({err})
    })
  }

  const reorder = (moveDown=true) => {
    let pageModuleIds = props.allPageModules.filter( m => !!m ).map( m => m._id);
    console.log(pageModuleIds)
    const _from = props.componentIndex;
    const _to = moveDown ? props.componentIndex + 1 : props.componentIndex - 1;
    pageModuleIds.splice(_to, 0, pageModuleIds.splice(_from, 1)[0]);
    console.log(pageModuleIds);
    setIsSaving(true);
    const path = createPaths(___appProps.site.parent._id, ___appProps.site._id, ___appProps.currentPage._id).singlePage;
    axios({
      method:'PUT',
      url: path,
      headers: {
        "Authorization": `Bearer ${Cookies.get('CREWAUTH')}`
      },
      data: {contentZone: pageModuleIds}
    }).then(res => {
      setShowEditForm(false)
      setIsSaving(false);
      console.log("SUBMIT TO API : PAGEMODULE REORDER");
      window.location.reload();
    }).catch(err => {
      console.log("FAILURE TO API : PAGEMODULE REORDER");
    })
  }

  const handleDeleteModule = (props, _id, name) => {
    if(confirm(`Are you sure you want to delete this ${name}`)){
      setIsSaving(true);
      // const contentZone = props.props.currentPage.contentZone.filter((zone) => zone._id !== _id);
      const path = createPaths(___appProps.site.parent._id, ___appProps.site._id, ___appProps.currentPage._id, _id).singleModule
      const data = { is_deleted: true };
      axios({
        method:'PUT',
        url: path,
        headers: {
          "Authorization": `Bearer ${Cookies.get('CREWAUTH')}`
        },
        data: data
      }).then(res => {
        setShowEditForm(false)
        setIsSaving(false);
        console.log("SUBMIT TO API : PAGEMODULE DELETE");
        window.location.reload();
      }).catch(err => {
        console.log("FAILURE TO API : PAGEMODULE DELETE");
      })
    }
  }

  const Component = props.component;
  if (!!canEdit) {
    return (<Fragment>
      {isSaving && <div className="coxswain coxswain-status">
        Saving...
      </div>}
      <div className="coxswain-editable-pagemodule">
        <div className={`inview-true fullyinview-true`}>
          <Component {...props.componentProps.pageName} {...props.componentProps.props} {...componentProps} {...props.componentProps._props} />
        </div>
        <div className="coxswain editable-pagemodule-toggle">
          {!!!showEditForm && <div className="coxswain edit-pagemodule-button-group">
            {props.componentIndex > 0 && <a onClick={ e => { e.preventDefault(); reorder(false); }} className="box-shadow" href="#">Move Up</a>}
            <a onClick={revealEditForm} className="box-shadow" href="#">Edit This {props.componentProps._props.name}</a>
            <a onClick={e => {
              e.preventDefault();
              handleDeleteModule(props.componentProps, componentId, props.componentProps._props.name)}} className="box-shadow" href="#">Delete This {props.componentProps._props.name}</a>
            <a onClick={e => {
              e.preventDefault();
              setAddModule(true);
            }} className="box-shadow" href="#">Add a new module</a>
            {props.componentIndex < props.allPageModules.length-1 && <a onClick={ e => { e.preventDefault(); reorder(); }} className="box-shadow" href="#">Move Down</a>}
          </div>}
        </div>
      </div>
      {addModule && <AddModule schema={props.allowedmodules} setShowAddModule={setAddModule} currentComponentID={props.componentProps._props._id} />}
      {!!showEditForm && <ComponentForm 
        setShowEditForm={revealEditForm} 
        componentSchema={componentSchema} 
        currentComponentProps={!!isCreateForm ? {} : componentProps}
        setComponentProps={setComponentProps}
        setEditorProps={setEditorProps} 
        editableProps={editableProps} 
        saveData={saveData} 
        onPropChange={!!isCreateForm ? () => {} : onPropChange}
        handleOnSubmit={handleOnSubmit}
        handleOnCancel={setComponentProps}
        isCreateForm={isCreateForm}
      />}
    </Fragment>)
  } else {
    return <ScrollMonitor><Component {...componentProps.pageName} {...componentProps.props} {...props.componentProps._props} {...componentProps._props} /></ScrollMonitor>
  }
}

export default ComponentEditor;