import React from 'react';

const SearchItem = ({htmlTitle, snippet, link}) => {
    return(<div className="search-item sp-m-sm">
        <a href={link}>
            <p className="c-secondary"><strong dangerouslySetInnerHTML={{__html: htmlTitle}}></strong></p>  
            <p className="c-background" dangerouslySetInnerHTML={{__html: snippet}}></p>
        </a>
    </div>)
}

export default SearchItem;