
import React, { useState } from 'react';
import Search from './../../icons/Search';
import SearchItem from './SearchItem';
import axios from 'axios';

const pageContains = (page, moduleName) => page.contentZone.filter(module => module.name === moduleName).length > 0;

const SearchPage = () => {
    const [searchResults, setSearchResults] = useState(false);
    const API_KEY = "AIzaSyBVUlgHQC-51eI9Ha4HWHRVYCX2uHdQdSU";
    const cx = "005253299979223132372:a_9jdyylwia";
    const URL = "https://www.googleapis.com/customsearch/v1?key=" + API_KEY + "&cx=" + cx + "&q="
    let errorMessage = "Sorry, we can't find what you’re looking for.";
    let [textInput, setTextInput] = useState("");

    const handleSearch = (e) => {
        e.preventDefault();
        let pages = ___appProps.flatSitemap.map( p => {
            return {...p, stores: pageContains(p, "DirectoryList") ? ___appProps.stores : [] }
        });
        const matches = pages.filter(p => JSON.stringify(p).toLowerCase().indexOf(textInput) > -1).map( p => {
            return {
                htmlTitle: p.pageTitle,
                link: p.crumb
            }
        });
        console.log(matches);
        setSearchResults(matches);
    }   

    const handleInput = (e) => {
        e.preventDefault();
        setTextInput(e.target.value)
        setSearchResults(false);
    }
    return(
        <div className="search-page">
            <div className="grid">
                <span className="divider"></span>
                <form className="search-form" onSubmit={(e) => handleSearch(e)}>
                    <label htmlFor="search">Search keyword</label>
                    <div role="group" style={{
                        display: 'flex',
                        margin: '20px 0',
                        width: '100%'
                    }}>
                        <input id="search" placeholder="Enter keywords" value={textInput} type="text" onChange={(e) => handleInput(e)}/>
                        <button onClick={e => handleSearch(e)} type="submit"><Search fill={'background'}/></button>
                    </div>
                </form>
                <div className="search-results">
                    {!!searchResults && !!searchResults.length > 0 
                    ? searchResults.map((res, i) => <SearchItem key={i} {...res}/>)
                    : !!searchResults && !!searchResults.length == 0 ? <h4 className="c-secondary">{errorMessage}</h4>
                    : null}
                </div>
            </div>
        </div>
    )
}

export default SearchPage;