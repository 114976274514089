import React from 'React';
import Heading from './../Heading/Heading';
import { replaceAssetsURL } from '../../helpers/helpers';

const Banner = ({title, content, link, featured_image, tags=false, featured_video, alignment, space_after, rmDarkBg = false}) => {
  return (<section className={`banner container ${alignment == 'full' ? "sp-p-super" : 'sp-p-xl' } sp-m-${space_after} alignment-${alignment}`}>
    <div className="inner-container">
      {!!tags && tags.map( (t,i) => !!t && <div className="button style-primary sp-m-sm" key={i}>{t}</div>)}
      <br />
      {!!title && <Heading content={title} className="sp-m-xs block" />}
      {!!content && <div className="sp-m-xs" dangerouslySetInnerHTML={{__html: content}}></div>}
      {alignment == 'full' && <hr className="banner_separator sp-m-md" />}
      <br />
      {!!link && !!link.url && <a className="button style-secondary" target={link.target ? link.target : ''} href={link.url} download={link.url.indexOf('.pdf') > -1 ? link.url : false}>{link.title}</a>}
    </div>
    {(!!featured_video || !!featured_image) && (<figure className={`a-swipe ${alignment == 'full' ? 'a-up' : 'a-left'} banner_image ${!!rmDarkBg && rmDarkBg == 'true' ? "" : "with-gradient"}`}>
        {!!featured_video ? <video muted autoPlay={true} loop={true} src={featured_video}></video> : <img src={replaceAssetsURL(featured_image, '?w=1160')} className={!!rmDarkBg && rmDarkBg == 'true' ? "object-contain" : ""} alt={title}/>}
    </figure>)}
  </section>)
}

export default Banner;