import React from 'React';
import { replaceAssetsURL } from '../../helpers/helpers';

const ContentMedia = ({title, content, featured_image, featured_video, featured_embed, link, thumbnail, flipOrientation="0", image_first_mobile=false, flip=false, pageName, space_after=null}) => {
  return (<section className={`contentmedia outer-container image-first-mobile-${image_first_mobile} flip-${flipOrientation === "1" || !!flip} sp-m-${space_after}`}>
    <div className="contentmedia_content inner-container">
      {!!thumbnail && (<figure className={`contentmedia_thumbnail sp-m-sm`}>
        <img src={replaceAssetsURL(thumbnail, "?w=1000")} alt={title}/>
    </figure>)}
      <h2 className="sp-m-sm">{title}</h2>
      <div className="richtext sp-m-sm" dangerouslySetInnerHTML={{__html: content }}></div>
      {!!link && !!link.title && !!link.url && <a className="sp-m-sm button style-primary " href={link.url} download={link.url.indexOf('.pdf') > -1 ? link.url : false}>{link.title}</a>}
    </div>
    {(!!featured_video || !!featured_image || !!featured_embed) && (<figure className={`contentmedia_image`}>
        {!!featured_video ? <video muted autoPlay={true} loop={true} src={featured_video}></video> : 
        !!featured_embed ? <div className="embed-container"><iframe src={featured_embed} /></div>
        : <img src={replaceAssetsURL(featured_image, "?w=725")} alt={!!title ? title : pageName}/>}
    </figure>)}
  </section>)
}

export default ContentMedia;