import React, { useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import Image from './../Image/Image';
import 'react-image-lightbox/style.css';

const Slider = ({images, space_after="", showTitleInModal=false, centerAlign=false}) => {
    const [photoIndex, setPhotoIndex] = useState(0);
    const [sliderOpen, setSliderOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth < 414)
        }
        checkMobile();
        window.addEventListener('resize', checkMobile)
        return () => window.removeEventListener('resize', checkMobile)
    }, [])

    console.log(centerAlign)

    const handleImageSelect = (i) => {
        setSliderOpen(true);
        setPhotoIndex(i);
    }


    return(<div className={`slider sp-m-${space_after}`}>
        <div className={centerAlign === "1" ? 'static-images container' : 'static-images container grid'} style={centerAlign === "1" ? {
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
            flexWrap: "wrap"
        } : {}}>
            {!!images && images.map((image, i) => {
                return !!image && <div key={i} className="image-container" style={centerAlign === "1" ? {
            height: '0px', width: !!isMobile ? "145px" : '165px', paddingTop: !!isMobile ? "145px" : '165px', position: 'relative'
        }: {height: '0px', paddingTop: '100%', position: 'relative'}} onClick={() => handleImageSelect(i)}>
                    <img style={centerAlign === "1" ? {
                        width: '100%', height: "100%", objectFit: 'cover', position: 'absolute', top: 0
        } :{ width: '100%', height: "100%", objectFit: 'cover', position: 'absolute', top: 0 }} src={image.featured_image} alt={image.title} />
                    {!!image.title && !!showTitleInModal && <div className="image-title" style={{
                        marginTop: '8px'
                    }}>{image.title}</div>}
                </div>
            })}
        </div>
        <div className="lightbox">
            {!!sliderOpen && <Lightbox
                closeLabel="CLOSE"
                imageCaption={!!showTitleInModal && !!images[photoIndex].title ? images[photoIndex].title : false}
                mainSrc={images[photoIndex].featured_image}
                nextSrc={images[(photoIndex + 1) % images.length].featured_image}
                prevSrc={images[(photoIndex + images.length - 1) % images.length].featured_image}
                onCloseRequest={() => setSliderOpen(false)}
                onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
            />}
        </div>
    </div>);
}

export default Slider;