import { has } from 'lodash';
import React, { useState } from 'react';
import ComponentItemEditor from '../ComponentItemEditor';
import ChangeParent from './ChangeParent';
import moduleSchema from './../../../component_schema';
import createPaths from '../../coxswain/apiconstants';
import ResourceList from './ResourceList';
import _ from 'lodash';
import axios from 'axios';
import Cookies from 'js-cookie';
import ComponentForm from '../ComponentForm';
import { orderedPages } from '../../helpers/helpers';

const ResourceItem = ({ parentItem, label, item, view, pages, dataName=false }) => {
    const [showZones, setShowZones] = useState(false);
    const [showComponentForm, setShowComponentForm] = useState(false);
    const [showParentForm, setShowParentForm] = useState(false);
    const [isPageProperties, setPageProperties] = useState(false)
    const [currentProps, setCurrentProps] = useState(item.props);
    const isPages = label.toLowerCase() === 'pages';
    const isModules = label.toLowerCase() === 'zones';
    const isUnits = label.toLowerCase() === 'tenant directory';
    const isNews = label.toLowerCase() === 'news';
    const isSites = label.toLowerCase() === 'sites';
    const isUsers = label.toLowerCase() === 'users';
    const hasParent = !!parentItem;
    const defaultSchema = dataName ? moduleSchema[dataName] : moduleSchema[item.name]
    const [componentProps, setComponentProps] = useState(item)
    const [componentSchema, setComponenSchema] = useState(defaultSchema);

    const handleContentZones = (e) => {
        e.preventDefault();
        setShowZones(!showZones);
    }

    const handleComponentForm = (e) => {
        e.preventDefault();
        setComponenSchema(defaultSchema)
        setShowComponentForm(!showComponentForm);
    }

    const changeParent = (e) => {
        e.preventDefault();
        setShowParentForm(!showParentForm);
    }

    const setEditorProps = ()=>{
        console.log("set editor props");
    }

    const onPropChange = (props) => {
        setComponentProps({
            ..._.cloneDeep(componentProps),
            ...props
        }); 
    }

    const handlePageProperties = (e) => {
        e.preventDefault();
        setPageProperties(!isPageProperties);
        setShowComponentForm(!showComponentForm);
    }

    const ApiRequest = async (method, path, data, showComponentForm) => {
        await axios({
            method: method,
            url: path,
            headers: {
                "Authorization": `Bearer ${Cookies.get('CREWAUTH')}`
            },
            data: data
        }).then(res => {
            !!showComponentForm && setShowComponentForm(!showComponentForm);
            window.location.reload()
        }).catch(err => {
            console.log({err})
        })
    }

    const handleOnSubmit = async (props, id, schema, isCreate)=>{
        
        const resource = isPages ? 'Page' : isNews ? 'News' : isUnits ? 'Store' : isModules ? 'Module' : isSites ? "Site" : isUsers ? "User" : false;
        if (!resource) {
            console.log('Undefined Resource');
            return false;
        }
        const resourceId = isPageProperties ? id : ___appProps.currentPage._id;
        const path = createPaths(___appProps.site.parent._id, ___appProps.site._id, resourceId, id, item._id)[`single${resource}`];
        let data = _.cloneDeep(props);
        delete data.id;
        data = isPages || isUsers ? props : {props: props};
        await ApiRequest('PUT', path, data, true)
    }

    const handleUserSubmit = async (props, id, schema, isCreate) => {
        if(!!props.sites && !!props.sites.length) {
            let sites = []
            props.sites.map(s => {
                !!s._id && sites.push(s._id)
            })
            props.sites = sites
        }
        // console.log({props})
        handleOnSubmit(props, id, schema, isCreate)
    }

    const handleDelete = async (e) => {
        e.preventDefault();
        const resource = isPages ? 'Page' : isNews ? 'News' : isUnits ? 'Store' : isModules ? 'Module' : isSites ? "Site" : isUsers ? "User" : false;
        const deletingItemName = item.title || item.name || "";
        if(!!resource && confirm(`Are you sure you want to delete this ${deletingItemName} ${resource.toLowerCase()} ?`)) {
            let id = isPages ? item._id : item.id; // This because in the first time we normalize data we have set `id` not `_id` to Store and News 
            let currentPageID = !!item._id ? item._id : ___appProps.currentPage._id;
            // If Item is PageModule then Check to get PageID by using his parentItem
            if(!!isModules && !!parentItem) {
                currentPageID = parentItem._id;
                id = item._id;
            }
            const path = createPaths(___appProps.site.parent._id, ___appProps.site._id, currentPageID, id, item._id)[`single${resource}`];
            await ApiRequest("PUT", path, { is_deleted: true }, false)
        }
    }

    const handleManageSite = (e) => {
        e.preventDefault();
        if(!!moduleSchema['UserSites']) {
            const sites = []
            !!___appProps.site.parent.sites && ___appProps.site.parent.sites.map(site => sites.push({displayName: site.title, value: site._id}))
            moduleSchema['UserSites'][0].repeaterSchema[0].options = sites || [];
            console.log("finished")
        } 
        setShowComponentForm(!showComponentForm);
        setComponenSchema(moduleSchema['UserSites'])
        console.log({handleManageSite: moduleSchema['UserSites']})
    }

    let title;
    if(isPages && hasParent){
        title = `/${parentItem.path}/${item.path}`;
    } else if (isPages && !hasParent){
        title = `/${item.path}`;
    } else if (label.toLowerCase() === 'zones'){
        title = item.name;
    } else if(isUsers) {
        title = item.fname + ' ' + item.lname;
    } else {
        title = item.title;
    }

    if(!!item.children) {
        item.children = orderedPages(item.children)
    }

    const componentFormProps = showComponentForm ? {
        name: "Edit",
        componentSchema: isPageProperties ? moduleSchema["PageProperties"] : componentSchema,
        isRepeater: false,
        setShowEditForm: isPageProperties ? handlePageProperties : handleComponentForm,
        currentComponentProps: isPageProperties ? componentProps : (isUnits || isNews || isUsers) ? componentProps : componentProps.props,
        id: isPageProperties ? componentProps._id : componentProps.id,
        setEditorProps: setEditorProps,
        isCreateForm: false,
        handleOnSubmit: !!isUsers ? handleUserSubmit : handleOnSubmit,
        onPropChange: onPropChange,
        componentName: isPageProperties ? componentProps.title : (isUnits || isNews)  ? dataName : componentProps.name,
        component: componentProps
    } : {};
    
    return (<div>
    <div className={`resource__item ${showZones ? "zones-open m-bottom-sm border-sm" : ''}`}>
        <div className={`resource__item_inner ${view === 'box' ? 'flex-column-space' : 'flex-row-space'} border-sm-light m-bottom-sm`}>
                <h4 className={`resource__title p-sm text-fore font-bold relaxes ${view === 'box' ? 'font-base' : 'font-base'}`}>{title}</h4>
                    { isPages ? 
                        <div className="resource__actions flex">
                            { isPages? <a className="font-sm p-sm text-upper font-bold text-highlight hover-text-highlight hover-bg-fore" href={item.path === "home" ? '/' : hasParent ? `/${parentItem.path}/${item.path}` : `/${item.path}`}>View Page</a> : null}
                            <a className="font-sm p-sm text-upper font-bold text-highlight hover-text-highlight hover-bg-fore" href="" onClick={handleContentZones}>{showZones ? 'Hide Components' : 'Edit Components'}</a>
                            <a className="font-sm p-sm text-upper font-bold text-highlight hover-text-highlight hover-bg-fore" href="" onClick={handlePageProperties}>Edit Page Properties</a>
                            <a className="font-sm p-sm text-upper font-bold text-highlight hover-text-highlight hover-bg-fore" href="" onClick={changeParent}>Change Parent</a>
                            <a className="font-sm p-sm text-upper font-bold text-highlight hover-text-highlight hover-bg-fore" href="" onClick={handleDelete}>Delete</a>
                        </div>
                        : isModules ? 
                            <div className="resource__actions flex">
                                <a className="font-sm p-sm text-upper font-bold text-highlight hover-text-highlight hover-bg-fore" href="" onClick={handleComponentForm}>Edit</a>
                                <a className="font-sm p-sm text-upper font-bold text-highlight hover-text-highlight hover-bg-fore" href="" onClick={handleDelete}>Delete</a>
                            </div>
                        : isSites ? <div className="resource__actions flex">
                            <a className="font-sm p-sm text-upper font-bold text-highlight hover-text-highlight hover-bg-fore" href={`/?siteId=${item._id}`}>View Site</a>
                        </div>
                        : isUsers ? (
                            item.role != 'crew' ? <div className="resource__actions flex">
                                <a className="font-sm p-sm text-upper font-bold text-highlight hover-text-highlight hover-bg-fore" href="" onClick={handleManageSite}>Permissions</a>
                                <a className="font-sm p-sm text-upper font-bold text-highlight hover-text-highlight hover-bg-fore" href="" onClick={handleComponentForm}>Edit</a>
                                <a className="font-sm p-sm text-upper font-bold text-highlight hover-text-highlight hover-bg-fore" href="" onClick={handleDelete}>Delete</a>
                            </div>
                            : <span className="font-sm p-sm text-upper font-bold text-highlight">CREW</span>
                        )
                        : <div className="resource__actions flex">
                        <a className="font-sm p-sm text-upper font-bold text-highlight hover-text-highlight hover-bg-fore" href="" onClick={handleComponentForm}>Edit</a>
                        <a className="font-sm p-sm text-upper font-bold text-highlight hover-text-highlight hover-bg-fore" href="">SEO</a>
                        <a className="font-sm p-sm text-upper font-bold text-highlight hover-text-highlight hover-bg-fore" href="" onClick={handleDelete}>Delete</a>
                    </div>
                    }
        </div>
        {showZones
            ? <ul className="component-zones">
                {!!item.contentZone && item.contentZone.map((zone, idx) => {
                    return (
                        <li>
                            <ResourceItem parentItem={item} label={"Zones"} view={view} item={zone} key={idx} pages={pages}/>
                        </li>
                    )
            })}
            </ul>
            : null
        }

        {showComponentForm && <ComponentForm {...componentFormProps}/> }
        
        {showParentForm ? <ChangeParent componentItemData={item} changeParent={changeParent} pages={pages} parentItem={parentItem} hasParent={hasParent}/> : null }
    </div>
    {!!item.children && item.children.map((child, idx)=>{
            let tmpParent = Object.assign({}, item) // To make sure this process will not effect the original sitemap. 
            tmpParent.path = !!parentItem ? `${parentItem.path}/${tmpParent.path}` : tmpParent.path
            return <ResourceItem parentItem={tmpParent} label={label} view={view} item={child} key={idx} pages={pages}/>
    })}
    </div>
    )}

export default ResourceItem;