import React, { useEffect, memo, useState, useRef } from 'react';
import { useForm, Controller } from "react-hook-form";
import _ from 'lodash';
import schemaJSON from '../../component_schema'
import { Editor } from '@tinymce/tinymce-react';
import Dropzone from 'react-dropzone'
import axios from 'axios';
import Cookies from 'js-cookie';
import createPaths from './apiconstants';
import Uploader from './Inputs/Uploader';
import UrlField from './Inputs/UrlField';

var flattenObject = function(ob) {
	var toReturn = {};
	
	for (var i in ob) {
		if (!ob.hasOwnProperty(i)) continue;
		
		if ((typeof ob[i]) == 'object') {
			var flatObject = flattenObject(ob[i]);
			for (var x in flatObject) {
				if (!flatObject.hasOwnProperty(x)) continue;
				
				toReturn[i + '.' + x] = flatObject[x];
			}
		} else {
			toReturn[i] = ob[i];
		}
	}
	return toReturn;
};

const ComponentForm = ({
  id,
  componentSchema, 
  isRepeater=false, 
  setShowEditForm, 
  currentComponentProps,
  setComponentProps,
  setEditorProps, 
  editableProps, 
  revealEditForm, 
  isCreateForm,
  handleOnSubmit=(d) => console.log(d), 
  handleOnCancel=(d) => console.log(d),
  onPropChange,
  componentName=false,
  component=false
}) => {
  const { register, getValues, handleSubmit, watch, errors, control, reset } = useForm();
  const [showRepeaterForm, setShowRepeaterForm] = useState(false);
  const [initialValues, setInitialValues] = useState(initialValues);
  const [schema, setSchema] = useState(isCreateForm ? [] : componentSchema)
  const [schemaName, setSchemaName] = useState(null)
  const [imgUrls, setImgUrls] = useState({})
  useEffect(()=>{
    setInitialValues(getValues());
  }, [])


  let newProps = watch();

  const onSubmit = (data) => {
    console.log(`this is my data for api: `, data);
    handleOnSubmit(data, id ? id : component ? component._id : currentComponentProps._id, schemaName, isCreateForm)
  }

  const handleSelectComponent = (e) => {
    if(!!schemaJSON[e.target.value]) {
      setSchemaName(e.target.value)
      setSchema(schemaJSON[e.target.value])
    } else {
      setSchemaName(null)
      setSchema(componentSchema)
    }
  }

  const handleCancel = (e) => {
    e.preventDefault(); 
    isRepeater ? setShowEditForm(false) : setShowEditForm(e, false);
    handleOnCancel(editableProps)
  }
  const uploadpath = createPaths(___appProps.site.parent._id, ___appProps.site._id).upload;
  const handleDrop = (acceptedFiles, name='image', idx, onChange) => {
    console.log(acceptedFiles[0]);
    var formData = new FormData();
    formData.append('image', acceptedFiles[0], acceptedFiles[0].name);
    
    axios.post(uploadpath, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        "Authorization": `Bearer ${Cookies.get('CREWAUTH')}`
      }
    }).then(res => {
      console.log(res.data.filepath)
      let tmp = imgUrls
      tmp[idx] = res.data.filepath
      setImgUrls(tmp)
      onChange(res.data.filepath);
    })
    .catch( err => console.log(err));
  }


  const handleIncreaseRepeater = (name) => {
    let sampleSchema = {}
    componentSchema.map(s => {
      if(s.name == name) {
        s.repeaterSchema.map(r => {
          sampleSchema[r.name] = ""
        })
      }
    })
    if(!!currentComponentProps[name]) {
      currentComponentProps[name].push(sampleSchema)
    } else {
      currentComponentProps[name] = []
      currentComponentProps[name].push(sampleSchema)
    }
    console.log({name, currentComponentProps, sampleSchema})
    onPropChange(currentComponentProps)
  }

  const handleRemoveRepeatable = (name, idx) => {
    if(confirm('Are you sure you want to delete ?')) {
      !!currentComponentProps[name] && currentComponentProps[name].splice(idx, 1);
      let data = {};
      componentSchema.map(s => {
        data[s.name] = currentComponentProps[s.name]
      })
      handleOnSubmit(data, id ? id : component ? component._id : currentComponentProps._id, schemaName, isCreateForm)
      // onPropChange(currentComponentProps)
    }
  }

  const handleMove = (idx, name, movestep) => {
    let link = currentComponentProps[name].splice(idx, 1)
    currentComponentProps[name].splice(idx + movestep, 0, ...link)
    onPropChange(currentComponentProps)
  }

  const handleOrderSubmit = (fieldName) => {
    if(confirm(`Are you sure you want to submit order ? \nThis process will work with the reordering item only!`)) {
      onSubmit(currentComponentProps);
    }
  }

  const renderSchema = (schema, inputPrefix="", displayPrefix="", currentComponentProps, register, control) => schema.map( (i, idx) => {
    // console.log(schema, i)
    const zoneOrRepeaterInputPrefix = !!inputPrefix ? inputPrefix + "." : "";
    if (typeof i === 'undefined' || !!!i) {
      return <div key={idx}>UNDEFINED</div>;
      // return false;
    } else if (i.type === 'input' || i.type === 'text' || i.type === 'date') {
      return (<div key={idx} className="m-bottom-md">
          <label className="text-fore p-left-sm" htmlFor={i.name} >{displayPrefix}{i.displayName}</label>
          <input name={`${inputPrefix}`+i.name} defaultValue={!!currentComponentProps[i.name] ? currentComponentProps[i.name] : ""} ref={register} type={i.type === 'date' ? 'date' : "text"} className="border-sm p-md text-fore bg-back"/>
          {!!i.displayDescription && <span className="desc p-left-sm">{i.displayDescription}</span>}
        </div>)
    } else if (i.type === 'url' ) {
      return (<div key={idx} className="m-bottom-md">
        <label className="p-left-sm text-fore">{displayPrefix}{i.displayName}</label>
        <div className="coxswain-dropzone border-sm p-md flex-align-center" style={{display: 'flex'}}>
            <Controller
              name={`${inputPrefix}`+i.name}
              control={control}
              rules={{ required: false }}
              defaultValue={!!currentComponentProps[i.name] ? currentComponentProps[i.name] : false}
              render={props => {
                return <React.Fragment>
                <UrlField {...props} type={i.type} />
              </React.Fragment>}}
            />
        </div>
      </div>)
    } else if (i.type === 'richtext') {
      return (<div key={idx} className="m-bottom-md">
        <label className="p-left-sm text-fore">{displayPrefix}{i.displayName}</label>
        <Controller
          name={`${inputPrefix}`+i.name}
          control={control}
          rules={{ required: false }}
          defaultValue={currentComponentProps[i.name]}
          render={props =>
            <Editor
              apiKey="u9m0xwinjulmbzr636zp4puq4inqw8jxy5bhc3difjapwbye"
              init={{
                plugins: 'link image code autolink',
                toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code | link'
              }}
              onEditorChange={ (content, editor) => {
                props.onChange(content)
              } }
              initialValue={currentComponentProps[i.name]}/>
          } // props contains: onChange, onBlur and value
        />
      </div>)
    } else if (i.type === 'image' || i.type === 'video' || i.type === 'file') {
      return <div key={idx} className="m-bottom-md">
        <label className="p-left-sm text-fore">{displayPrefix}{i.displayName}</label>
        <div className="coxswain-dropzone border-sm p-md flex-align-center" style={{display: 'flex'}}>
            <Controller
              name={`${inputPrefix}`+i.name}
              control={control}
              rules={{ required: false }}
              defaultValue={!!currentComponentProps[i.name] ? currentComponentProps[i.name] : false}
              render={props => {
                return <React.Fragment>
                <Uploader {...props} type={i.type} />
              </React.Fragment>}}
            />
        </div>
      </div>
    } else if (i.type === 'zone') {
        return <div key={idx}>
          {renderSchema(i.zoneSchema, zoneOrRepeaterInputPrefix+"."+i.name+".", i.displayName+": ", !!currentComponentProps[i.name] ? currentComponentProps[i.name] : {}, register, control)}
        </div>
    } else if (i.type === 'choice' || i.type === 'boolean') {
      i.options = i.type === 'choice' ? i.options : [{displayName: "Yes", value: 1}, {displayName: "No", value: 0}]
      return (<div  key={idx}>
          <label className="p-left-sm text-fore">{displayPrefix}{i.displayName}</label>
          <select ref={register} defaultValue={currentComponentProps[i.name]} name={`${inputPrefix}`+i.name} className="border-sm p-md text-fore bg-back m-bottom-md">
            <option value="" hidden>Select {displayPrefix}{i.displayName}</option>
            {
              !!i.options && i.options.map((option, idx) => (
                <option value={option.value} selected={currentComponentProps[i.name] == option.value ? "selected" : ""} key={idx}>{option.displayName}</option>
              ))
            }
          </select>
          {!!i.displayDescription && <span className="desc p-left-sm m-bottom-md">{i.displayDescription}</span>}
        </div>)
    } else if (i.type === 'repeater'){
      let defaultRepeaterProps = {};
      i.repeaterSchema.forEach( repeaterField => { defaultRepeaterProps[repeaterField.name] = "" });
      const repeaterLength = !!currentComponentProps[i.name] && Array.isArray(currentComponentProps[i.name]) ? currentComponentProps[i.name] : 1;
      return <div className="m-bottom-md">
        <label className="p-left-sm text-fore">{displayPrefix}{i.displayName}</label>
        {!!currentComponentProps[i.name] && Array.isArray(currentComponentProps[i.name]) && currentComponentProps[i.name].map( (item, idx) => (
            <div className="p-left-sm p-bottom-sm p-top-sm p-right-sm border-sm m-bottom-sm">
              <div style={{
                display: 'flex', justifyContent: 'space-between'
              }}>
                <span>{showRepeaterForm === i.name+"."+idx+"." ? ` ` : item[i.repeaterDisplayKey]}</span>
                <span>
                  {idx != 0 && <span style={{marginRight: idx != currentComponentProps[i.name].length - 1 ? "5px" : "30px"}} onClick={() => handleMove(idx, i.name, -1)} title="move up">▲</span>}
                  {idx != currentComponentProps[i.name].length - 1 && <span style={{marginRight: idx != 0 ? "20px" : "30px"}} onClick={() => handleMove(idx, i.name, 1)} title="move down">▼</span>}
                  <span style={{marginRight: "20px"}} onClick={e => {
                    e.preventDefault();
                    setShowRepeaterForm(showRepeaterForm === i.name+"."+idx+"." ? false : i.name+"."+idx+".");
                  }}>{showRepeaterForm === i.name+"."+idx+"." ? "Close This Repeater Form" : "Edit"}</span>
                  <span onClick={() => handleRemoveRepeatable(i.name, idx)}>Remove</span>
                </span>
              </div>
              <div style={{display: showRepeaterForm === i.name+"."+idx+"." ? 'block' : 'none'}}>
                {!!i.repeaterSchema && renderSchema(i.repeaterSchema, i.name+"."+idx+".", "", !!currentComponentProps[i.name][idx] ? currentComponentProps[i.name][idx] : {}, register, control)}
              </div>
            </div>))}
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          <span style={{cursor: "pointer", marginRight: "20px"}} onClick={() => handleOrderSubmit(i.name)}>Submit Order</span>
          <span style={{cursor: "pointer"}} onClick={() => handleIncreaseRepeater(i.name)}>Add Another</span>
        </div>
      </div>
    } else if (i.type === 'stringarray'){ 
      return (<div key={idx} className="m-bottom-md">
        <label className="p-left-sm text-fore">{displayPrefix}{i.displayName}</label>
        <Controller
          name={`${inputPrefix}`+i.name}
          control={control}
          defaultValue={currentComponentProps[i.name]}
          render={props =>
            <input
              className="border-sm p-md text-fore bg-back"
              onChange={ e => {
                try {
                  const newVal = e.target.value.split(',');
                  props.onChange(newVal)
                } catch (error) {
                  console.log(error);
                }
              } }
              value={!!props && !!props.value ? props.value.join(',') : ""}/>
          }
        />
      </div>)
  } else {
    // return <div key={idx}>{JSON.stringify(i)}</div>;
    return false;
  }
})

  // console.log({schema})
  if (typeof schema !== 'undefined') {
    return (<div className="coxswain coxswain-module-form coxswain-module-new coxswain-reset-tree z-top">
    {/* typeof currentComponentProps !== 'undefined' && !!currentComponentProps.name && <h2>{currentComponentProps.name}</h2> */}
    <div className="coxswain-module-name p-sm text-back bg-fore font-bold">{ componentName ? componentName : currentComponentProps.name}</div>
    <div className="coxswain-module-container">
      <form onSubmit={handleSubmit(onSubmit)}>
      <div className="coxswain-inputs">
            {renderSchema(schema, '', "", currentComponentProps, register, control)}
      </div>
      <div className="coxswain-bottom-buttons bg-fore">
        <span onClick={handleCancel} href="" className="text-highlight p-left-md">Cancel</span>
        <input type="submit" className="p-md bg-fore text-highlight font-bold"/>
      </div>
  </form></div></div>)
  } else {
    return false;
  }
}

export default ComponentForm;