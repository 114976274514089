import React, {useState} from 'react';
import createPaths from '../apiconstants';
import Uploader from './Uploader';

const UrlField = (props) => {
  const {type, onChange, value} = props;
  const [uploader, setUploader] = useState(true);
  return <div className="coxswain-url-field">
    <div className="m-bottom-md">
          {!!uploader 
          ? <Uploader {...props} type="file" /> 
          : (<React.Fragment>
            <label className="text-fore p-left-sm">Enter URL Below.</label>
            <input value={value} onChange={props.onChange} type="text" className="border-sm p-md text-fore bg-back"/>
          </React.Fragment>)}
          <div onClick={ e => setUploader(!uploader)} className="p-top-sm p-bottom-sm">Click to {uploader ? 'manually enter a url' : 'select from the DAM'}</div>
    </div>
  </div>
}

export default UrlField;