import React, { useState, useEffect } from 'react';
import ResourceItem from './ResourceItem';
import DropAndDropResourceWrapper from './DropAndDropResourceWrapper';
import ComponentForm from './../ComponentForm';
import moduleSchema from './../../../component_schema';
import axios from 'axios';
import Cookies from 'js-cookie'
import createPaths from './../apiconstants'
import _ from 'lodash'
import { orderedPages } from '../../helpers/helpers';

const ResourceList = ({label, data, view='bar', sortBy, pages, schemaName, path, inprogress = false}) => {
    const [ searchTerm, setSearchTerm ] = useState('');
    const [showAddForm, setShowAddForm] = useState(false)
    const [submitPath, setSubmitPath] = useState(path)
    const [isSaving, setIsSaving] = useState(true)
    const [isReordering, setIsReordering] = useState(false);
    const [componentProps, setComponentProps] = useState(null);
    const componentSchema = moduleSchema[schemaName];
    const [cloneSchema, setCloneSchema] = useState(null)
    const isUnits = label.toLowerCase() === 'tenant directory';
    const isSites = label.toLowerCase() === 'sites'

    let _data = _.cloneDeep(data);
    
    // Sort store by alphabetical
    if (isUnits) {
        _data = _.sortBy(_data, d => d.title)
    }

    // console.log({componentSchema, data});
    if(schemaName.toLowerCase() == 'pages') {
        componentSchema.map(s => {
            if (s.name == 'parent') {
                let options = pages.map(p => {
                    return {
                        displayName: p.title,
                        value: p._id
                    }
                })
                s.options.push(...options)
            }
        })
        _data = orderedPages(_data)
    }

    const revealAddForm = (e, toggle=true) => {
        e.preventDefault();
        const body = document.getElementsByTagName('body')[0];
        body.classList.add('coxswain-locked');
        setShowAddForm(toggle);
        !!!toggle && setCloneSchema(null)
    }

    const handleAddNew = (e, toggle=true) => {
        let tmp = {}
        componentSchema.map(schema => {
            tmp[schema.name] = ""
        })
        setComponentProps(tmp)
        setSubmitPath(path)
        setShowAddForm(toggle)
    }

    const onPropChange = (props) =>{
        setComponentProps({
          ..._.cloneDeep(componentProps),
          ...props
        });
    }

    const handleOnSubmit = (props, _id, name, isCreate) => {
        const auth = Cookies.get('CREWAUTH');
        if(submitPath ==  createPaths().createUser) {
            props.propertygroups = window.___appProps.site.parent._id
        }
        // console.log({auth, path, props, _id, name, isCreate})

        if(!!auth) {
            setIsSaving(true)
            axios({
                method: "POST",
                url: submitPath,
                headers: {
                    "Authorization": `Bearer ${auth}`
                },
                data: props
            }).then(res => {
                console.log({res, componentProps})
                setShowAddForm(false)
                setIsSaving(false);
                window.location.reload()
            }).catch(err => {
                console.log({err})
                alert(err.toString())
            })
        }
    }

    const handleCloneSite = (e, toggle=true) => {
        let tmp = {}
        let tmpSchema = moduleSchema['CloneSite']
        let sites = []
        !!___appProps.site.parent.sites && ___appProps.site.parent.sites.map(site => sites.push({displayName: site.title, value: site._id}))
        tmpSchema[1].options = sites;
        setCloneSchema(tmpSchema)
        setComponentProps(tmp)
        setShowAddForm(toggle)
        setSubmitPath(createPaths(___appProps.site.parent._id).cloneSite)
    }

    console.log(_data);
    return (<div className="--coxswain-resources bg-back p-md z-base">
        <div className="resources__top flex-row-space flex-align-center">
            <h3 className="text-fore">{label}.</h3>
            <div className="buttons-right flex-row-space flex-align-center">
                <a className="font-sm p-md text-upper font-bold text-highlight border-sm  hover-text-highlight hover-bg-fore" href="#add-new" onClick={handleAddNew}>Add New</a>
                {!!isSites && <a className="font-sm p-md text-upper font-bold text-highlight border-sm  hover-text-highlight hover-bg-fore" href="#clone-site" onClick={handleCloneSite}>Clone a site</a>}
                {schemaName.toLowerCase() == 'pages' && <a className="font-sm p-md text-upper font-bold text-highlight border-sm  hover-text-highlight hover-bg-fore" href="#clone-site" onClick={e => { setIsReordering(!isReordering) }}>{!!isReordering ? 'Cancel Reordering' : 'Reorder Pages'}</a>}
                {/*<a className="font-sm p-md text-upper font-bold text-highlight border-sm  hover-text-highlight hover-bg-fore" href="" >Reorder Pages</a>*/}
                <form action="" className="m-bottom-md">
                    <label htmlFor="search"></label>
                    <input type="text" name="search" id="search" className="border-sm p-sm text-fore" value={searchTerm} onChange={e => {setSearchTerm(e.target.value)}} placeholder="Type to search and filter"/>
                </form>
            </div>
        </div>
        {
            !!inprogress ? "Processing..." 
            : isReordering ? <DropAndDropResourceWrapper initialData={_data} /> : <ul className={ view === 'box' ? 'resources--box' : 'resources--bar'}>
                {
                    !!_data && _data.filter((item, i)=> searchTerm.length > 0 ? JSON.stringify(item).toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 : true)
                        .map((d, i) => {
                        return <ResourceItem parentItem={null} label={label} view={view} item={d} key={i} pages={pages} dataName={schemaName}/>
                    })
                }
            </ul>
        }
        {!!showAddForm && !!componentSchema && !!componentProps && <ComponentForm 
            setShowEditForm={revealAddForm} 
            componentSchema={cloneSchema || componentSchema} 
            currentComponentProps={componentProps} 
            onPropChange={onPropChange}
            handleOnSubmit={handleOnSubmit}
            // handleOnCancel={setComponentProps}
        />}
    </div>)
    }

export default ResourceList;