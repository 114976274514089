import React, { useEffect, memo, useState, useRef, Fragment } from 'react';
import _ from 'lodash';
import Dropzone from 'react-dropzone'
import axios from 'axios';
import Cookies from 'js-cookie';
import createPaths from './../apiconstants';
import loadingSrc from './../../icons/loading.svg'
import './Uploader.scss';

const fileTypes = {
  image: ["gif","jpg","jpeg","tiff","png"],
  video: ["mp4","ogg"],
  file: ["pdf"],
}

const fileTests = {
  image: /[\/.](gif|jpg|jpeg|tiff|png)$/i,
  video: /[\/.](mp4|ogg)$/i,
  file: /[\/.](pdf)$/i,
}


const CopyUrlField = ({url}) => {
  const copyToClipBoard = () => {
    var textField = document.createElement('textarea')
    textField.innerText = `${url.indexOf("http") === 0 ? '' : window.location.protocol }${url}`;
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove();
    alert('value copied to clipboard');
  }
  return <div className="selectable-url"><span className="selectable-url-preview">{url}</span><div onClick={copyToClipBoard} className="selectable-url-copy">✄</div></div>
}

const Uploader = (props) => {
  const [siteUploads, setSiteUploads] = useState(false);
  const [damImage, setDamImage] = useState(false);
  const [showItemListing, setShowItemListing] = useState(false);
  const [selected, setSelected] = useState(props.value)
  const [loading, setLoading] = useState(false)
  const {type, onChange} = props;

  useEffect(() => {
  }, []);

  const fetchUploads = () => {
    const _fetchUploads = async () => {
      var data = new FormData();
      const path = createPaths(___appProps.site.parent._id, ___appProps.site._id).upload;
      var config = {
        method: 'get',
        url: path,
        headers: { 
          "Authorization": `Bearer ${Cookies.get('CREWAUTH')}`,
        },
        data: data
      };
      axios(config).then(res => {
        setSiteUploads(res.data);
      });
    }
    _fetchUploads();
  }

  const openItemListing = () => {
    setShowItemListing(true);
    fetchUploads();
  }

  const handleDrop = (acceptedFiles, name='image', idx, onChange = () => {}) => {
    setLoading(true)
    var formData = new FormData();
    formData.append('image', acceptedFiles[0], acceptedFiles[0].name);
    const path = createPaths(___appProps.site.parent._id, ___appProps.site._id).upload;
    axios.post(path, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        "Authorization": `Bearer ${Cookies.get('CREWAUTH')}`
      }
    }).then(res => {
      setLoading(false)
      setSiteUploads([...siteUploads, {url: res.data.filepath}])
      setDamImage(res.data.filepath);
      setSelected(res.data.filepath);
    })
    .catch( err => {
      setLoading(false)
      console.log(err)
    });
  }

  const cancelDAM = () => {
    setShowItemListing(false);
    setDamImage(false);
  }

  const confirmImage = () => {
    onChange(selected);
    setShowItemListing(false);
  }

  const handleImageClick = (i) => {
    setDamImage(i.url);
    setSelected(i.url)
  }
  
  const handleRemoveDAM = () => {
    setSelected(null)
    onChange(null);
  }

  const selectedArray = !!selected ? selected.split('.') : false;
  const selectedFilename = !!selectedArray && selectedArray.length > 3 ? selectedArray[selectedArray.length - 2].split('/')[selectedArray[selectedArray.length - 2].split('/').length - 1] : 'No file selected';
  const selectedFileType = !!selectedArray && selectedArray.length > 3 ? selectedArray[selectedArray.length - 1] : '';
  return (<Fragment>
    <div className="upload-dam">
      <div className="dam-current" onClick={ e => { openItemListing() } }>
      {!!!props.value && type != 'file' ? 
        <span></span>
      : type === 'image' ? 
        <img src={props.value} alt=""/>
      : type === 'video' ? 
        <video src={props.value} controls>Your browser does not support the video tag.</video>
      : <CopyUrlField url={props.value} />
      }</div>
      <div className="dam-button">
        <span className="dam-open-dam" onClick={ e => { openItemListing() } }>Open DAM</span>
        {!!selected && <span className="dam-remove-dam" onClick={handleRemoveDAM}>Remove DAM</span>}
      </div>
    </div>
    {!!showItemListing && <div className="m-bottom-md dam-container">
      {!!loading && <div className="loading">
        <img src={loadingSrc} alt="loading..." />
      </div>}
      <div className="dam-header">
        <div onClick={e => confirmImage() } className="confirm">CONFIRM </div>
        <div onClick={e => cancelDAM() } className="close">CLOSE × </div>
      </div>
      <div className="dam-inner-container">
          {!!siteUploads ? <div className="dam-listing">
              {type === 'image' ?
                siteUploads.filter( i => fileTests[type].test(i.url) )
                  .map( (i, idx) => <img onClick={(e) => handleImageClick(i)} className={`dam-upload dam-image selected-${ i.url === damImage }`} src={i.url} key={idx} />)
              : type === 'video' ?
                siteUploads.filter( i => fileTests[type].test(i.url) )
                  .map( (i, idx) => <video onClick={(e) => handleImageClick(i)} className={`dam-upload dam-video selected-${ i.url === damImage }`} src={i.url} key={idx}>Your browser does not support video</video>)
              : 
                siteUploads.filter( i => fileTests[type].test(i.url) )
                  .map( (i, idx) => {
                    let tmp = i.url.split('/')
                    const name = tmp[tmp.length - 1]
                    return <div onClick={(e) => handleImageClick(i)} className={`dam-upload dam-file selected-${ i.url === damImage }`} key={idx}>
                    <svg width="80px" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                        <path fill="#000" d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zm-22.6 22.7c2.1 2.1 3.5 4.6 4.2 7.4H256V32.5c2.8.7 5.3 2.1 7.4 4.2l83.9 83.9zM336 480H48c-8.8 0-16-7.2-16-16V48c0-8.8 7.2-16 16-16h176v104c0 13.3 10.7 24 24 24h104v304c0 8.8-7.2 16-16 16zm-22-171.2c-13.5-13.3-55-9.2-73.7-6.7-21.2-12.8-35.2-30.4-45.1-56.6 4.3-18 12-47.2 6.4-64.9-4.4-28.1-39.7-24.7-44.6-6.8-5 18.3-.3 44.4 8.4 77.8-11.9 28.4-29.7 66.9-42.1 88.6-20.8 10.7-54.1 29.3-58.8 52.4-3.5 16.8 22.9 39.4 53.1 6.4 9.1-9.9 19.3-24.8 31.3-45.5 26.7-8.8 56.1-19.8 82-24 21.9 12 47.6 19.9 64.6 19.9 27.7.1 28.9-30.2 18.5-40.6zm-229.2 89c5.9-15.9 28.6-34.4 35.5-40.8-22.1 35.3-35.5 41.5-35.5 40.8zM180 175.5c8.7 0 7.8 37.5 2.1 47.6-5.2-16.3-5-47.6-2.1-47.6zm-28.4 159.3c11.3-19.8 21-43.2 28.8-63.7 9.7 17.7 22.1 31.7 35.1 41.5-24.3 4.7-45.4 15.1-63.9 22.2zm153.4-5.9s-5.8 7-43.5-9.1c41-3 47.7 6.4 43.5 9.1z"></path>
                    </svg>
                    <span>{name}</span>
                  </div>
                  })
              }
          </div> : <div>Fetching all uploads</div> }
      </div>
      <div className="dam-dropzone-container coxswain-dropzone flex-align-center" style={{display: 'flex'}}>
        <div className="dam-selected-item">
          <div className="dam-current">
            {!!!selected ? 
              <span></span>
            : type === 'image' ? 
              <img src={selected} alt=""/>
            : type === 'video' ? 
              <video src={selected} controls>Your browser does not support the video tag.</video>
            : false
            }
            <div className="dam-current-details">
              <h3 className="dam-heading">Selected File</h3>
              {!!selectedFilename && <div className="dam-detail"><strong>Name :</strong> {selectedFilename}<br/></div>}
              {!!selectedFileType && <div className="dam-detail"><strong>Type :</strong> {selectedFileType}<br/></div>}
              {!!selected && <CopyUrlField url={selected} />}
            </div>
          </div>
        </div>
        <div className="dam-dropzone">
          <h3 className="dam-heading">Upload a new file</h3>
          <Dropzone multiple={false} accept="image/png, image/jpeg, video/*, application/pdf" onDrop={acceptedFiles => {handleDrop(acceptedFiles, 'image', props.onChange)} }>
              {({getRootProps, getInputProps}) => (
                <section className="dam-dropzone-inner">
                  <div className="dam-dropzone-inner-hotdrop" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>Drag and drop a media item here, or click to select a file from your hard drive.</p>
                  </div>
                </section>
              )}
          </Dropzone>
        </div>
      </div>
    </div>}
  </Fragment>)
}

export default Uploader;