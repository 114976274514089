import React from 'react';
import vars from './../components/AppVars.json';
import _ from 'lodash';

let varsAsArray = Object.keys(vars).map((breakpoint, i) => {
    let properties = Object.values(vars)[i];
    let propertiesMapped = Object.keys(properties).map((property, pi) => {
        return {[property]: Object.values(properties)[pi]}
    });
    return {[breakpoint]: propertiesMapped}
});

const backFilledVars = varsAsArray.map((breakpoint, i) => {
    let properties = Object.values(breakpoint)[0];
    let previousBreakpoint = varsAsArray[i-1];
    let previousProperties = !!previousBreakpoint ? Object.values(previousBreakpoint)[0] : false;
    if (!!previousProperties) {
        _.map(previousProperties, (property) => {
            let propertyKey = Object.keys(property)[0];
            let currentProperties = _.map(properties, (currentProp) => Object.keys(currentProp)[0]);
            if (currentProperties.filter((prop) => prop == propertyKey).length == 0) {
                properties.push(property);
            }
        });
    }
    return properties;
});

export default backFilledVars;