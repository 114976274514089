import React, {useState, Fragment} from 'react';

const ContentSwitcher = ({contents=[]}) => {
    const [current, setCurrent] = useState(0);
    return (<div className="container">
        <div className="contentswitcher_container">
            <ul className="contentswitcher_control">
                {!!contents && contents.map( (item,idx) => <li onClick={e => setCurrent(idx)} className={`current-${current === idx}`}>{item.title}</li>)}
            </ul>
            <section className="contentswitcher_content">
                {!!contents && contents.map( (item, idx) => <div className={`contentswitcher_item current-${current === idx}`}>
                    <h2 className="contentswitcher_item_title">{item.title}</h2>
                    <div className="contentswitcher_item_content" dangerouslySetInnerHTML={{__html: item.content}} />
                </div>)}
            </section>
        </div>
    </div>);
}

export default ContentSwitcher;