import React, { useEffect, useState } from "react";
import axios from "axios";
import leasingData from "./../../helpers/leasingData";
import LeasingAccordion from "./LeasingAccordion";
import Building from "./../../icons/Building";
import Share from "./../../icons/Share";
import Slider from "./../Slider/Slider";
import ButtonLinks from "./../ButtonLinks/ButtonLinks";
import createFindSpaceQuery from "./createFindspaceQuery";

import loadingSrc from "./../../icons/loading.svg";

import bomaIcon from "./icon-boma.svg";
import leedIcon from "./icon-leed.svg";
import parkingIcon from "./icon-parking.svg";
import totalSpaceIcon from "./icon-totalSpace.svg";
import floorsIcon from "./icon-floors.svg";
import elevatorIcon from "./icon-elevator.svg";
import numberOfStoresIcon from "./icon-numberOfStores.svg";

function formatNumberWithCommas(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

const Leasing = (props) => {
  const { findspaceIds } = props;
  console.log("findspaceIds", findspaceIds);
  return !!findspaceIds && !!findspaceIds.length ? (
    findspaceIds
      .map((i) => i.trim())
      .map((id) => <SingleLeasing id={id} key={id} />)
  ) : (
    <div>FINDSPACE IDS ARE NEEDED</div>
  );
};

const SingleLeasing = ({ id }) => {
  const [leasingInfo, setLeasingInfo] = useState(false);
  useEffect(() => {
    if (!leasingInfo) {
      getLeasingData();
    }
  });

  const getLeasingData = () => {
    // const q = createFindSpaceQuery(id);
    // var data = JSON.stringify({
    //     query: q,
    //     variables: {}
    // });

    let auth = `Basic MTRhNTY2NWE2Y2M2NGE3MTliYTI0ZWI0ZTJiNDFlNmM6IStrenNiK3ZsJDBsPW4lOTV3Nmx5dCMmaDh2enNxYzYmdzM5dF4lbkBveGYrMTE2aGk=`;
    var config = {
      method: "get",
      url: `https://dev-api.findspace.com/api/v1/buildings/${id}`,
      headers: {
        Authorization: auth,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(async function (response) {
        await axios
          .get(`https://dev-api.findspace.com/api/v1/buildings/${id}/suites`, {
            headers: {
              Authorization: auth,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            console.log("suites", res.data);
            setLeasingInfo({
              item: {
                ...response.data.data,
                available_suites:
                  !!res.data.data &&
                  !!res.data.data.suites &&
                  !!res.data.data.suites.length
                    ? res.data.data.suites
                    : [],
              },
            });
          })
          .catch((err) => {
            return false;
          });
        // const mediaurls = .map( i => i.url);
        return response.data;
      })
      .catch(function (error) {
        return false;
      });
  };
  return leasingInfo ? (
    <div>
      {Object.keys(leasingInfo)
        .filter((key) => !!leasingInfo[key])
        .map((key) => {
          const building = leasingInfo[key];
          return <BuildingInfo key={key} building={building} />;
        })}
    </div>
  ) : (
    <div className="container">
      <div
        className="loading"
        style={{ maxWidth: "100px", width: "100%", margin: "0 auto" }}
      >
        <img src={loadingSrc} alt="loading..." />
      </div>
      <br />
      <br />
    </div>
  );
};

const BuildingInfo = ({ building }) => {
  const [open, setOpen] = useState(false);
  const buildingType = {
    O: {
      title: "Office",
      icon: Building,
    },
    R: {
      title: "Retail",
      icon: Building,
    },
  };

  const galleryImages = building.buildingmedia_set
    .map((i) => {
      return {
        featured_image: i.media,
        title: "",
        mime: i.mime_type,
      };
    })
    .filter((i) => i.mime.match(/image/i));
  console.log(galleryImages);

  const downloads = building.buildingmedia_set
    .map((i) => {
      return {
        url: i.media,
        target: "_blank",
        title: "View PDF",
        thumbnail: "//crew-quadreal-cc.azurewebsites.net/img/icons/vision.svg",
        mime: i.mime_type,
      };
    })
    .filter((i) => i.mime.match(/pdf/i));

  const buildingDescription = building.buildinginformation_set.filter(
    (i) => i.title.toLowerCase().indexOf("description") > -1
  );

  return (
    <section className="leasing">
      <div className="building-introduction bg-quinary sp-p-md sp-m-lg">
        <div className="grid container">
          {galleryImages.length > 0 && (
            <img
              className="image"
              src={galleryImages[0].featured_image}
              alt=""
            />
          )}
          <div className="title">
            <h2 className="c-primary sp-m-xs">{building.name}</h2>
            <h6 className="sp-m-xs">{building.city}</h6>
          </div>
          {!!building.available_suites && (
            <div className="content">
              <p className="fs-md fw-semi">
                Building Area :{" "}
                {formatNumberWithCommas(
                  building?.buildingoffice?.total_space ||
                    building?.buildingretail?.total_space ||
                    building?.buildingindustrial?.total_space ||
                    building?.rentable_area ||
                    0
                )}{" "}
                sq ft
              </p>
              {building.available_suites.length == 0 && (
                <p className="fs-md">
                  <span>{building.available_suites.length}</span> Suites
                  available
                </p>
              )}
            </div>
          )}
          <div className="divider">
            {!!building.available_suites &&
              building.available_suites.length > 0 && (
                <LeasingAccordion suites={building.available_suites} />
              )}
          </div>
          <div className="building-links sp-p-xs">
            {!!buildingType[building._type] && (
              <div className="building-type">
                {buildingType[building._type].icon()}
                <h6 className="tt-uc fw-bold">
                  {buildingType[building._type].title}
                </h6>
              </div>
            )}
            <div className="building-share">
              <div
                style={{ cursor: "pointer" }}
                className="pillbox sp-p-md sp-m-lg"
                onClick={(e) => {
                  setOpen(!open);
                }}
              >
                {open ? "Collapse Details" : "View Details"}
              </div>
            </div>
            {/* <div className="building-share">
                            <a className="tt-uc fw-bold c-foreground" href={building.buildingoffice.buildingWebsite}>
                                <Share/> Share
                            </a>
                        </div> */}
          </div>
        </div>
        {open && (
          <div className="available-suites">
            <div className="building-overview grid container">
              {Object.keys(building.buildingoffice).map((key) => {
                const detail = building.buildingoffice[key];
                if (key === "totalSpace" && !!detail) {
                  return (
                    <div className="detail" key={key}>
                      <img src={totalSpaceIcon} alt="" />
                      <span className="building-overview-detail building-overview-detail-title">
                        <strong>TOTAL SPACE</strong>
                      </span>
                      <span className="building-overview-detail">
                        {formatNumberWithCommas(detail)}
                      </span>
                    </div>
                  );
                }
                if (key === "bomaBest" && !!detail) {
                  return (
                    <div className="detail" key={key}>
                      <img src={bomaIcon} alt="" />
                      <span className="building-overview-detail building-overview-detail-title">
                        <strong>BOMA BEST</strong>
                      </span>
                      <span className="building-overview-detail">
                        {detail == "G"
                          ? "GOLD"
                          : detail == "P"
                          ? "PLATINUM"
                          : detail}
                      </span>
                    </div>
                  );
                }
                if (key === "belowGroundStalls" && !!detail) {
                  return (
                    <div className="detail" key={key}>
                      <img src={parkingIcon} alt="" />
                      <span className="building-overview-detail building-overview-detail-title">
                        <strong>PARKING STALLS</strong>
                      </span>
                      <span className="building-overview-detail">{detail}</span>
                    </div>
                  );
                }
                if (key === "leed" && !!detail) {
                  return (
                    <div className="detail" key={key}>
                      <img src={leedIcon} alt="" />
                      <span className="building-overview-detail building-overview-detail-title">
                        <strong>LEED CERTIFICATION</strong>
                      </span>
                      <span className="building-overview-detail">
                        {detail == "G"
                          ? "GOLD"
                          : detail == "P"
                          ? "PLATINUM"
                          : detail}
                      </span>
                    </div>
                  );
                }
                if (key === "elevator" && !!detail) {
                  return (
                    <div className="detail" key={key}>
                      <img src={elevatorIcon} alt="" />
                      <span className="building-overview-detail building-overview-detail-title">
                        <strong>ELEVATORS</strong>
                      </span>
                      <span className="building-overview-detail">{detail}</span>
                    </div>
                  );
                }
                if (key === "numberOfFloors" && !!detail) {
                  return (
                    <div className="detail" key={key}>
                      <img src={floorsIcon} alt="" />
                      <span className="building-overview-detail building-overview-detail-title">
                        <strong>FLOORS</strong>
                      </span>
                      <span className="building-overview-detail">{detail}</span>
                    </div>
                  );
                }
                if (key === "numberOfStores" && !!detail) {
                  return (
                    <div className="detail" key={key}>
                      <img src={numberOfStoresIcon} alt="" />
                      <span className="building-overview-detail building-overview-detail-title">
                        <strong>NUMBER OF STORES</strong>
                      </span>
                      <span className="building-overview-detail">{detail}</span>
                    </div>
                  );
                }
              })}
            </div>
            {!!buildingDescription.length && (
              <div className="building-content container sp-m-lg tennantcost">
                <p
                  dangerouslySetInnerHTML={{
                    __html: buildingDescription[0].content,
                  }}
                ></p>
              </div>
            )}
            {open && (
              <div className="building-content grid container sp-m-lg">
                <br />
                <br />
                {downloads.length > 0 && (
                  <div
                    className="downloads"
                    style={{ gridColumn: "1 / span 12" }}
                  >
                    <ButtonLinks title="Media" links={downloads} />
                  </div>
                )}
                <div
                  className="galleryImages"
                  style={{ gridColumn: "1 / span 12" }}
                >
                  <Slider images={galleryImages} />
                </div>
              </div>
            )}
            {/* Tenant Cost */}
            {(!!building.buildingoffice.realtyTax ||
              !!building.buildingoffice.utilities ||
              !!building.buildingoffice.operatingCosts) && (
              <div className="tennantcost container sp-m-lg">
                <h4>Tenant Cost</h4>
                <table className="building-details-table">
                  <tbody>
                    {!!building.buildingoffice.realtyTax && (
                      <tr>
                        <td>Realty Tax</td>
                        <td>${building.buildingoffice.realtyTax} (psf/yr)</td>
                      </tr>
                    )}
                    {!!building.buildingoffice.utilities && (
                      <tr>
                        <td>Utilities</td>
                        <td>${building.buildingoffice.utilities} (psf/yr)</td>
                      </tr>
                    )}
                    {!!building.buildingoffice.operatingCosts && (
                      <tr>
                        <td>Operating Costs</td>
                        <td>
                          ${building.buildingoffice.operatingCosts} (psf/yr)
                        </td>
                      </tr>
                    )}
                    {!!building.buildingoffice.operatingCosts && (
                      <tr>
                        <td>Additional Rent Total</td>
                        <td>
                          $
                          {parseFloat(
                            building.buildingoffice.realtyTax +
                              building.buildingoffice.utilities +
                              building.buildingoffice.operatingCosts
                          ).toFixed(2)}{" "}
                          (psf/yr)
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}

            <div className="container">
              <br />
              <br />
              <h3>Leasing Agents</h3>
            </div>
            <div
              className="container"
              style={{
                display: "grid",
                gridTemplateColumns: "50% 50%",
                gridGap: "20px",
                marginTop: "40px",
              }}
            >
              {!!building.buildingcontacts_set && building.buildingcontacts_set.map((c) => (
                <div className="building-content-contact" key={c.id}>
                  <strong>
                    {c.contact.first_name} {c.contact.last_name}
                  </strong>
                  <br />
                  <br />
                  {c.contact.title}
                  <br />
                  <br />
                  QuadReal Property Group, Brokerage
                  <br />
                  <br />
                  <a className="dont-break-out" href={`tel:${c.contact.phone}`}>
                    {c.contact.phone}
                  </a>
                  <br />
                  <br />
                  <a
                    className="dont-break-out"
                    href={`mailto:${c.contact.email}`}
                  >
                    {c.contact.email}
                  </a>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {/* open && <div className="featured-image" style={{backgroundImage: "url(" + building.image + ")"}}></div> */}
    </section>
  );
};

export default Leasing;
