import React, { useState } from 'react';
import Heading from './../Heading/Heading';

const SingleEvent = ({event}) => {
    const [buttonFullLabel, setButtonFullLabel] = useState(false);
    const addToCalendar = () => {}
    const eventType = JSON.parse(event.EventType).label;
    return<article className='single-event'>
        <div className="outer-container grid bg-primary" style={{backgroundImage: !!event.coverImage ? 'radial-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)), url(' + event.coverImage + ')' : null}}>
                <div className="date-wrapper">
                    <p>{event.eventDate}</p>
                    <p>{event.fromTime} - {event.toTime}</p>
                </div>
                <div className="main-content-wrapper sp-p-sm">
                    <Heading content={event.Title} level={2} className="c-background sp-m-xs"/>
                    {!!event.EventCategory && <p className="sp-m-xs">{eventType}: {JSON.parse(event.EventCategory).label}</p>}
                </div>
        </div>
    </article>
}

export default SingleEvent;