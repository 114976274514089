import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import ComponentForm from './../ComponentForm';
import Icons from 'phosphor-react';
import axios from 'axios';
import Cookies from 'js-cookie'
import createPaths from './../apiconstants';
import componentSchema from './../../../component_schema'

const ignoreSchema = ['Units', 'Sites', 'SiteSettings', 'UserSites', 'Users', 'Pages', 'PageProperties', 'News', 'CloneSite']

const AddModule = ({schema, currentPage, setShowAddModule, currentComponentID}) => {
    const [allmoduleSchema, setAllModuleSchema] = useState(schema);
    const [moduleType, setModuleType] = useState(false);
    const [showModuleSchema, setShowModuleSchema] = useState(false);
    const [moduleSchema, setModuleSchema] = useState(false);
    const [moduleProps, setModuleProps] = useState({});

    useEffect(()=>{
        setAllModuleSchema(window.___appProps.allowedmodules);
    }, [])

    const revealEditForm = () => {
        const body = document.getElementsByTagName('body')[0];
        body.classList.add('coxswain-locked');
        setShowModuleSchema(false);
        setModuleType(false);
        setModuleProps({});
    }

    const saveData = data => {
        const contentZone = ___appProps.currentPage.contentZone || null
        const position = !!contentZone ? _.findIndex(contentZone, c => c._id == currentComponentID) : 9999;
        const path = createPaths(___appProps.site.parent._id, ___appProps.site._id,  ___appProps.currentPage._id).createModule;
        var formData = {
            props: data,
            name: moduleType,
            position: position
        }
        axios({
            method: 'POST',
            url: path,
            headers: {
                "Authorization": `Bearer ${Cookies.get('CREWAUTH')}`
            },
            data: formData
        }).then(res => {
            window.location.reload()
        }).catch(err => {
            console.log({err})
        });
        console.log('this is my formdata', formData);
    }

    const onPropChange = (props) => {
        setModuleProps({
            ..._.cloneDeep(moduleProps),
            ...props
        })
    }
    
    return (<div className="coxswain-reset-tree addmodule-modal coxswain coxswain-module-form">
        <div className="addmodule-inner p-top-md">
            <h2>Add a Module to the page</h2>
        </div>
        {/* {typeof allmoduleSchema !== undefined && !showModuleSchema && !!allmoduleSchema && (<div className="addmodule-inner">
            {allmoduleSchema.map( s => (<div onClick={e => {setModuleType(s.title); setModuleSchema(s.schema_props) }} key={s._id} className={`addmodule-single selected-${s.title === moduleType}`}>
                <h3 className="font-base">{s.title}</h3>
                {!!s.description && <p>{s.description}</p>}
                {!!s.tags && s.tags.map( (tag, idx) => <span key={idx} className="addmodule-single-tag font-sm">{tag}</span> )}
            </div>))}
        </div>)} */}
        
        
        {!!componentSchema && <div className="addmodule-inner">
            {Object.keys(componentSchema).map((schema, i) => !!!ignoreSchema.includes(schema) && <div onClick={e => {setModuleType(schema); setModuleSchema(componentSchema[schema]) }} key={i} className={`addmodule-single selected-${schema === moduleType}`}>
                <h3 className="font-base">{schema}</h3>
                {/* {!!s.description && <p>{s.description}</p>}
                {!!s.tags && s.tags.map( (tag, idx) => <span key={idx} className="addmodule-single-tag font-sm">{tag}</span> )} */}
            </div>)}
        </div>}
        <div className="addmodule-inner p-top-md">
            {!showModuleSchema && moduleSchema && <div onClick={e => {
                setShowModuleSchema(true);
                }} className="bg-highlight text-back text-fore font-bold p-md block">Add Module</div>}
            {!showModuleSchema && <div onClick={e => {
                setShowAddModule(false);
            }} className="bg-fore text-back text-back font-bold p-md block">Cancel Add New Module</div>}
        </div>
        {!!showModuleSchema && <ComponentForm 
            setShowEditForm={revealEditForm} 
            componentSchema={moduleSchema} 
            currentComponentProps={moduleProps}
            setComponentProps={setModuleProps}
            setEditorProps={setModuleProps} 
            editableProps={{}} 
            saveData={saveData} 
            onPropChange={onPropChange}
            handleOnSubmit={saveData}
            handleOnCancel={revealEditForm}
            isCreateForm={false}
        />}
    </div>);
}

export default AddModule;