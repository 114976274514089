import React from "React";
import Heading from './../Heading/Heading';
import PageTitle from './../PageTitle';
import Carousel from 'nuka-carousel';
import { replaceAssetsURL } from "../../helpers/helpers";

const Hero = (props) => {
  const timer = !isNaN(Number(props.timer)) ? Number(props.timer) : 3000
  const speed = !isNaN(Number(props.speed)) ? Number(props.speed) : 500

  return (<div className={`hero ${!!props.heroes && props.heroes.length > 1 && "slide-max-height"}`}>
    {!!props.heroes ?
    <Carousel
      autoplay={true}
      autoplayInterval={timer}
      speed={speed}
      wrapAround={props.heroes.length > 1}
      renderBottomCenterControls={({ currentSlide }) => (<></>)}
      renderCenterLeftControls={({ previousSlide }) => (<></>)}
      renderCenterRightControls={({ nextSlide }) => (<></>)}
    >
      {props.heroes.map( (hero, idx) => <article className="hero_slide sp-p-super" key={idx}>
          <div className="hero_slide_container container">
            <Heading level={1} content={hero.title} className="hero_slide_title sp-m-md" />
            <div className="hero_slide_content sp-m-md" dangerouslySetInnerHTML={{__html: hero.content}}></div>
            {!!hero.link && !!hero.link.url && <a className="button style-primary" href={hero.link.url} target={!!hero.link.target ? hero.link.target : ""} download={hero.link.url.indexOf('.pdf') > -1 ? hero.link.url : false}>{hero.link.title}</a>}
          </div>
          <figure className="hero_slide_image with-gradient">

            <img src={!!hero.featured_image ? replaceAssetsURL(hero.featured_image, '?w=1440') : ''} alt={hero.title}/>
          </figure>
        </article>)}
    </Carousel> : <PageTitle {...props} />}
  </div>)
}

export default Hero;