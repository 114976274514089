import React, {useEffect, useState} from 'react';
import Cookies from 'js-cookie'
import createPaths from './../apiconstants';
import axios from 'axios'
import ResourceList from './ResourceList';

const Users = ({label, schemaName, sites}) => {
    const [items, setItems] = useState([])
    const [inprogress, setInProgress] = useState(true)

    useEffect(() => {
        const path = createPaths().userListing
        const propertyId = ___appProps.site.parent._id || null
        const user = JSON.parse(Cookies.get('CREWUSER'))
        axios({
            method:'GET',
            url: `${path}?propertygroups=${propertyId}&is_deleted=false`,
            headers: {
                "Authorization": `Bearer ${Cookies.get('CREWAUTH')}`
            }
        }).then(async res => {
            console.log({res})
            setInProgress(false)
            if(res.status == 200 && !!res.data.data) {
                let data = res.data.data.filter(d => d.role != 'crew' && d._id != user._id);
                await Promise.all(data.map(async d => {
                    delete d.password;
                    if(!!d.sites && !!d.sites.length) {
                        const path = createPaths(propertyId).sites
                        d.sites = await getSiteBelongToUser(path, d.sites)
                    }
                }))
                console.log({data})
                setItems(data)
            }
        }).catch(err => {
            console.log({err})
        })

        /**
         * To Get All Sites by SiteIds
         * @param {*} path 
         * @param {*} propertyId 
         * @param {Array} siteids 
         * 
         * @returns Object
         */
        const getSiteBelongToUser = async (path, siteids) => {
            return await axios({
                method:'GET',
                url: `${path}?_id=${siteids.join(',')}&is_deleted=false`,
                headers: {
                    "Authorization": `Bearer ${Cookies.get('CREWAUTH')}`
                }
            })
            .then(res => {
                if(res.status == 200) {
                    return res.data.data;
                }
            })
            .catch(err => {
                console.log({err})
                return err;
            })
        }
    }, [])

    return <ResourceList 
        label={label} 
        schemaName={schemaName}
        path={createPaths().createUser}
        data={items}
        view="bar" 
        sortBy="alphabetical"
        sites={sites}
        inprogress={inprogress}
    />
}

export default Users;