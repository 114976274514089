import React, { useState } from 'react';
import MobileTopNav from './MobileTopNav';
import MenuList from './MenuList';
import SearchPage from './SearchPage';
import Arrow from './../../icons/Arrow'
import Quad from './../../icons/Quad';
import _ from 'lodash';

const MobileMenu = ({menuItems, site}) => {
    const [parentItem, setParentItem] = useState(false);
    const [CurrentMenuItems, setCurrentMenuItems] = useState([menuItems]);
    const [searchMode, setSearchMode] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleSearchMode = () => {
        setSearchMode(!searchMode);
        if (!!menuOpen) {
            setMenuOpen(false);
        }
    }

    const toggleMenuOpen = () => {
        setMenuOpen(!menuOpen);
        if (!!searchMode) {
            setSearchMode(false);
        }
    }

    const pushItems = (items, parentItem) => {
        let menus = Object.values(CurrentMenuItems);
        menus.push(items);
        !!parentItem && setParentItem(parentItem);
        setCurrentMenuItems(menus);
    }

    const navigatePrevious = () => {
        let menus = Object.values(CurrentMenuItems);
        menus.splice(-1,1)
        setParentItem(false);
        setCurrentMenuItems(menus);
    }
    
    return(
        <header id="header-mobile">
            <MobileTopNav toggleSearchMode={toggleSearchMode} toggleMenuOpen={toggleMenuOpen} menuOpen={menuOpen} searchMode={searchMode} site={site}/>
            <div className={`menu-contents ${!!searchMode || !!menuOpen ? 'open' : ''}`}>
                {!!searchMode 
                ? <SearchPage />
                : !!menuOpen ? <React.Fragment>
                    <div id="nav-menu-wrapper">
                        {CurrentMenuItems.length > 1 && <button onClick={navigatePrevious} className="prev-menu">
                            <Arrow width={'30'}/>
                        </button>}
                        <MenuList pushItems={pushItems} parentItem={parentItem} items={CurrentMenuItems[CurrentMenuItems.length - 1]} />
                        <a className="quad" target="_blank" href="https://www.quadreal.com">
                            QuadReal Connect
                            <Quad />
                        </a>
                    </div>
                </React.Fragment> 
                : null}
            </div>
        </header>
    )
}

export default MobileMenu;

