import React, {useState} from 'react';

const AccordionItem = ({question, answer}) => {
    const [open, setOpen] = useState(false);
    return <div className={`sp-p-sm container accordion_single open-${open}`}>
        <h5 onClick={ e => setOpen(!open)} className={`accordion_title open-${open} ${open && 'sp-m-sm' }`}>
            {question}
        </h5>
        <svg onClick={ e => setOpen(!open)} className={`accordion_single_arrow open-${open}`} width="18px" height="10px" viewBox="0 0 18 10" version="1.1">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.296851749">
                <g id="(d)-download-forms" transform="translate(-1094.000000, -688.000000)" fill="#333333" fillRule="nonzero">
                    <g id="Group-4" transform="translate(435.000000, 578.000000)">
                        <g id="Group-3" transform="translate(0.000000, 93.000000)">
                            <path d="M663.00872,22.1506323 C663.041625,22.4397203 663.168918,22.7087577 663.369769,22.9137221 L670.864291,30.6249455 C671.181545,30.9496495 671.643069,31.0755248 672.074987,30.9551492 C672.506906,30.8347736 672.84359,30.4864379 672.958198,30.041374 C673.072805,29.59631 672.947922,29.1221444 672.630597,28.7975141 L666.024083,22 L672.630597,15.2024859 C672.947922,14.8778556 673.072805,14.40369 672.958198,13.958626 C672.84359,13.5135621 672.506906,13.1652264 672.074987,13.0448508 C671.643069,12.9244752 671.181545,13.0503505 670.864291,13.3750545 L663.369769,21.0862779 C663.09656,21.364937 662.96337,21.7575731 663.00872,22.1506323 L663.00872,22.1506323 Z" id="Path" transform="translate(668.000000, 22.000000) rotate(-90.000000) translate(-668.000000, -22.000000) "></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        <div className={`accordion_content rich_text open-${open}`} dangerouslySetInnerHTML={{__html: answer}} />
    </div>
}


const Accordion = ({accordions}) => (<section className="sp-m-lg"> 
    {!!accordions && accordions.map((accordion,idx) => <AccordionItem {...accordion} key={idx} />)} 
</section>)


Accordion.editorProps = [
    {
        "name": "accordions",
        "type": "repeater",
        "repeaterModel": [
            {
                "name": "question",
                "type": "text"
            },
            {
                "name": "answer",
                "type": "richtext"
            }
        ]
    } 
]

export default Accordion;