import App from './components/App';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { hydrate } from 'react-dom';

hydrate(<App {...window.___appProps} />, document.getElementById('root'));

if (module.hot) {
  module.hot.accept();
}
