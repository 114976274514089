import React, { useEffect, useState } from 'react';
import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';

const Menu = ({sitemap, site}) => {
    const [menuItems, setMenuItems] = useState(sitemap);
    const [siteData, setSiteData] = useState(site)
    useEffect(() => {
        setSiteData(window.___appProps.site)
        setMenuItems(window.___appProps.sitemap);
    }, []);
    return(!!menuItems && <React.Fragment>
        <DesktopMenu menuItems={sitemap} site={siteData}/>
        <MobileMenu menuItems={sitemap} site={siteData}/>
    </React.Fragment>)
}

export default Menu;