import React, {useEffect, useState} from 'react';
import AppVars from './AppVars.js';
import Coxswain from './../coxswain/Coxswain';
import LayoutCard from './LayoutCard/LayoutCard';
import quadSchemas from './../helpers/quadSchemas';
import ZoneRender from './ZoneRender';
import Footer from './Footer/Footer';
import Menu from './Menu/Menu';
import './../styles/styles.scss';
import './../styles/quadreal.scss';
import _ from 'lodash';
import CookieConsent from "react-cookie-consent";
import Cookies from 'js-cookie'
import axios from 'axios';
import createPaths from './../coxswain/apiconstants';

const flattenSiteMap = (sitemapNested, parentCrumb="") => {
  let flat = [];
  Object.keys(sitemapNested).forEach( key => {
    let currentPage = _.cloneDeep(sitemapNested[key]);
    if (!!currentPage) { 
      currentPage.name = sitemapNested[key].title; 
      currentPage.crumb = sitemapNested[key].path === 'home' ? `${parentCrumb}/` : `${parentCrumb}/${sitemapNested[key].path}`;
      currentPage.pageTitle = sitemapNested[key].title;
      flat.push(currentPage) 
    };
    if (!!currentPage && currentPage.hasOwnProperty('children')) {
      Object.keys(currentPage.children).forEach( childkey => {
        let _currentPage = _.cloneDeep(currentPage.children[childkey]);
        const crumb = `${currentPage.crumb}/${currentPage.children[childkey].path}`
        if (!!_currentPage) { 
          _currentPage.name = currentPage.children[childkey].title; 
          _currentPage.crumb = crumb
          _currentPage.pageTitle = currentPage.children[childkey].title;
          flat.push(_currentPage) 
        };
        !!currentPage.children[childkey].children &&
        !!currentPage.children[childkey].children.length && 
        flat.push(...flattenSiteMap(currentPage.children[childkey].children, crumb))
      });
    }
  })
  return flat;
}

const App = (props) => {
  const [sitemap, setSitemap] = useState(props.sitemap)
  const [site, setSite] = useState(props.site)
  const [repo, setRepo] = useState(props)
  const [resource, setResource] = useState(props)

  // If Logged in, get all pages, news, stores and site object from API and use that instead of publish state

  useEffect(() => {
    const auth = Cookies.get('CREWAUTH');
    setSitemap(null)
    setSite(null)
    setRepo(null)
    setResource(null)
    if(!!auth && !!props.site && !!props.site.parent) {
      const path = createPaths(props.site.parent._id, props.site._id).getDevelopmentResource
      axios({
        method: 'POST',
        url: path,
        headers: {
          "Authorization": `Bearer ${auth}`
        }
      })
      .then(async res => {
        if(res.status == 200) {
          let repository = res.data;
          const currentUrl = window.location.pathname;
          const flatSitemap = flattenSiteMap(repository.pages);
          const urlWithoutSlash = currentUrl[currentUrl.length - 1] === '/' ? currentUrl : currentUrl + '/';
          const currentPage = flatSitemap.filter( i => {
            const crumb = i.crumb[i.crumb.length - 1] === '/' ? i.crumb : i.crumb + '/';
            return crumb == urlWithoutSlash;
          });

          let events = [];
          if (currentUrl.indexOf('news-events') > -1) {
            // try {
            //   const r = await getPremiseAccessToken();
            //   events = await getPremiseEvents(r.access_token);
            // } catch (error) {
            //   console.log(error);
            // }
          }
          const news = _.flatten(repository.news.map(n => { return {...n.props, id: n._id } }));
          const stores = _.flatten(repository.stores.map(s => { return {...s.props, id: s._id } }));
          const context = {
            sitemap: repository.pages,
            allowedmodules: repository.allowedmodules,
            stores: stores,
            news: news,
            site: repository.site,
            flatSitemap: flatSitemap,
            currentPage: currentPage.length > 0 ? currentPage[0] : {crumb: '404'},
            currentUrl: currentUrl,
            events: events
          };
          console.log({context})
          window.___appProps = context;
          console.log('db appprops', {context})
          setResource(context);
          setRepo(repository);
          setSite(repository.site)
          setSitemap(repository.pages);
        }
      })
      .catch(err => {
        console.log({err})
      })
    } else {
      setSitemap(props.sitemap)
      setSite(props.site)
      setRepo(props)
      setResource(props)
    }
  }, []);

  return(<React.Fragment>
      {!!sitemap && !!site && !!repo && !!resource && <>
      <Coxswain {...resource} sitemap={sitemap}/>
      <AppVars/>
      <Menu sitemap={sitemap} site={site}/>
      <ZoneRender {...resource} news={repo.news} sitemap={sitemap} site={site}/>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="quadrealcommercecourt"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px", fontFamily: `var(--ff-body), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif` }}
        expires={150}
      >
        <div style={{display: `flex`, alignItems: `baseline`}}>
          <p>This website uses cookies including third-party cookies, which allow QuadReal to obtain information about your visit to the website. Please click the “Privacy” link if you would like to learn more about the cookies used on this website. By continuing to use this website, you consent to the use of cookies.</p>
          <a target="_blank" href="https://www.quadreal.com/privacy-policy/" style={{
            background: `rgb(255, 212, 45)`,
            border: `0px`,
            display: `block`,
            borderRadius:` 0px`,
            boxShadow:` none`,
            color: `rgb(78, 80, 59)`,
            cursor: `pointer`,
            flex: `0 0 auto`,
            padding: `5px 10px`,
            margin: `15px`,
            fontSize:` 13px`
          }}>Privacy</a>
        </div>
      </CookieConsent>
      <Footer site={site}/>
    </>}
  </React.Fragment>)
}

export default App;
