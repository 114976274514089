import React from "react";
import Heading from './../Heading/Heading';

const Callout = ({title, type='center', space_below="null"}) => (<section className={`fa-${type} container callout sp-p-lg sp-m-${space_below}`}>
    <Heading className={`fa-${type} align-${type}`} content={title} level={2} lineEmphasis="primary" />
  </section>);

Callout.editorProps = [
  {
    "name": "title",
    "type": "text",
  },
  {
    "name": "type",
    "type": "checkbox",
  },
]

export default Callout;