import React from 'react';
import getLayoutRatios from './getLayoutRatios';
import Components from './../index';
import getShorthandClasses from './../../helpers/getShorthandClasses';
import _ from 'lodash';

const LayoutCard = ({schema, windowWidth, contentProps}) => {
    let cardItem = {};
    let subComponentSchema = schema.subComponentSchema;
    const loopItems = (schema) => {    
        return schema.layout.map((layoutItem) => {
            return {
                name: layoutItem.name,
                type: layoutItem.type,
                componentSchema: !!subComponentSchema[layoutItem.name] ? subComponentSchema[layoutItem.name] : false,
                items: !!layoutItem.layout ? loopItems(layoutItem) : false,
                ratioKey: !!layoutItem.ratioKey ? layoutItem.ratioKey : false,
                contain: layoutItem.contain == false ? false : true,
            }
        });
    }
    cardItem.ratioKey = schema.ratioKey;
    cardItem.componentSchema = !!schema.componentSchema ? schema.componentSchema : false;
    cardItem.items = !!schema.layout ? loopItems(schema) : false;
    cardItem.contain = schema.contain == false ? false : true;


    const childElements = (item) => {
        if (!!item.items.length > 0) {
            return render(getLayoutRatios(item, windowWidth));
        }
    }

    const renderCurrentComponent = (item) => {
        let currentComponent = _.filter(Components, (component, name) => name == item.type)[0];
        let children = childElements(item);
        if (!!currentComponent && !!children) {
            return currentComponent({
                'items': children,
                ...contentProps[item.name],
                className: getShorthandClasses(item.componentSchema)
            });
        } else if (!!currentComponent && !!!children) {
            return currentComponent({
                ...contentProps[item.name],
                className: getShorthandClasses(item.componentSchema)
            });
        } else if (!!!currentComponent) {
            `COMPONENT TYPE "${item.type}" NOT DEFINED.`
        }
    }

    const render = (cardData) => {
        return cardData.items.map((_items, rowIndex) => {
            return _items.length > 0 && ( 
                <div key={rowIndex} className={!!!cardData.contain ? `full-bleed` : `grid`} style={
                    !!!cardData.contain ? null :
                    {gridTemplateColumns: `repeat(${cardData.gridSpan}, 1fr)`,
                    gridAutoColumns: `${cardData.gridSpan}fr`}}>
                {_items.map((item, idx) => {
                    return <div key={idx} className={`box`} style={{
                        gridColumn: `auto / span ${cardData.currentRatioSpans[idx]}`}}>
                        {renderCurrentComponent(item)}
                    </div>
                })}
            </div>)
        })
    }

    return (<div className={`layout-card ${schema.name}`}>
        {render(getLayoutRatios(cardItem, windowWidth))}
    </div>)
}

export default LayoutCard; 