import React from 'react';
import Heading from './../Heading/Heading';
import RichText from './../RichText';

const NewsListing = ({appProps, space_after}) => {
  const news = appProps.news

  return (<section className={`news-listing sp-m-${space_after} container`}>
    {!!news && news.map((item, idx) => {
      const date = !!item && !!item.props && !!item.props.created_at ? new Date(item.props.created_at) : false;
      return (<article key={idx} className="single-newsitem">
        <div className="outer-container grid">
            {!!date && <div className="date-wrapper">
                <p>{date.toDateString()}</p>
            </div>}
            <div className="main-content-wrapper sp-p-sm">
                <Heading content={item.props.title || ''} level={2} className="c-primary sp-m-xs"/>
                <RichText content={item.props.content || ''} />
            </div>
        </div>
      </article>)
    })}
  </section>)
}

export default NewsListing;