import React, { useState, useEffect } from 'react';
import MenuList from './MenuList';
import SearchToggle from './SearchToggle';
import SearchPage from './SearchPage';
import Quad from './../../icons/Quad';
import Arrow from './../../icons/Arrow';
import CommerceCourt from './../../icons/CommerceCourt.svg';
import { replaceAssetsURL } from '../../helpers/helpers';

const DesktopMenu = ({menuItems, site}) => {
    const [scrolledDown, setScrolledDown] = useState(false);
    useEffect(() => {
        window.addEventListener('scroll', (e) => {
            if (window.scrollY > 100) {
                setScrolledDown(true);
            } else {
                setScrolledDown(false);
            }
        });
    }, []);
    const [searchMode, setSearchMode] = useState(false);
    let eyebrow = {
        "https://visitor.commerce-court.com/": {
            "pageTitle": "PAL",
            "icon": "//crew-quadreal-cc.azurewebsites.net/img/logos/pal-logo.png",
        },
        "https://www.quadrealconnect.com/": {
            "pageTitle": "QuadReal Connect",
            "icon": "//crew-quadreal-cc.azurewebsites.net/img/logos/QR-connect-logo-white.svg",
        }
    }
    let main = {};

    const toggleSearchMode = () => {
        if (!searchMode) {
            document.body.classList.add('search-true');
        } else if (!!searchMode) {
            document.body.classList.add('search-false');
            document.body.classList.remove('search-true');
        }
        setSearchMode(!searchMode);
    }
    
    Object.keys(menuItems).map((item, i) => {
        if (item.indexOf('https://') > -1) {
            eyebrow[item] = Object.values(menuItems)[i];
        } else {
            main[item] = Object.values(menuItems)[i];
        }
    });
    return(<header id="header-desktop" className={`search-mode-${searchMode}`}>
        <div className="main-menu-container">
            <a className="site-logo" href="/">
                <img src={replaceAssetsURL(site.logo, "?w=120")} alt={site.title}/>
            </a>
            <MenuList overrideChildClick={true} pushItems={false} items={main} includeChildren={true}/>
            <SearchToggle toggleSearchMode={toggleSearchMode} searchMode={searchMode} />
            {!!searchMode && <SearchPage />}
        </div>
    </header>)    
}

export default DesktopMenu;