import React from 'react';

const Hamburger = ({fill='tertiary'}) => {
	return(<React.Fragment>
        <svg fill={`var(--c-${fill})`} width="25px" height="15px" viewBox="0 0 25 15" version="1.1">
            <rect id="Rectangle" x="0" y="12.8000399" width="25" height="1.5"></rect>
            <rect id="Rectangle" x="0" y="6.40001994" width="25" height="1.5"></rect>
            <rect id="Rectangle" x="0" y="-2.90878432e-14" width="25" height="1.5"></rect>
        </svg>
	</React.Fragment>)
}

export default Hamburger;