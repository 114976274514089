import React, {useEffect, useState} from 'react';
import axios from 'axios';
import SingleEvent from './SingleEvent';
import Modal from './../Modal/Modal';

const PremiseCalendar = ({appKey, clientId, space_after="lg", events}) => {
  const [currentEvent, setCurrentEvent] = useState(false);
  const currenteventType = currentEvent ? JSON.parse(currentEvent.EventType).label : '';
  const currentEventCategory = currentEvent ? JSON.parse(currentEvent.EventCategory).label : '';
  const modalEvent = currentEvent ? (<article className="modal_store modal_event">
        <div className="title-container">
            <figure className="store_image">
                {!!currentEvent.coverImage && <img src={currentEvent.coverImage} alt={currentEvent.Title}/>}
            </figure>
            <h2 className="sp-m-sm title c-background">{currentEvent.Title}</h2>
        </div>
        <div className="modal_store_content">
            <div className="contact-info"><p className="sp-m-sm">{!!currentEvent.openTo ? `Open to: ${currentEvent.openTo}` : null}</p></div>
            <div className="pillbox sp-m-md">{currenteventType} : {currentEventCategory}</div>
            {!!currentEvent.Details && <p>{currentEvent.Details}</p>}
            <br />
            <a target="_blank" href={`https://www.premisehq.co/Events/index.embeded.html?type=details&eventId=${currentEvent.Did}&client=96f1258064df4ef1bf2dbb49c07e84dd`} className="pillbox bg-primary sp-m-lg">Click here to register</a>
        </div>
    </article>) : false;


  const edt = !!events && !!events.Data ? JSON.parse(events.Data) : [];

  const fetchEvents = () => {
    // ALL Properties
    // let propertyListUrl = `https://www.premisehq.co/api/Forms/PropertyList?clientId=${clientId}&limit=200`
    // axios.get(propertyListUrl)
    // .then((res) => {
    //   console.log(res);
    // })
    // .catch((err) => {
    //   console.log(err);
    // })
  }

  return <div className={`premise-event-listing sp-m-${space_after}`}>
    {!!edt && edt.map((event, i) => <div onClick={(e) => setCurrentEvent(event)}><SingleEvent key={i} event={event}/></div>)}
    <Modal show={!!currentEvent} closeModal={ (e) => setCurrentEvent(false) }>{modalEvent}</Modal>
  </div>
  // return <div id="EVMAppHolder" data-type="calendar" data-property="659429667fee45698be3ccdc41cac3a7" data-client="cc3fdca374904ae89e3393779805efaf" data-year="2020" data-month="8"> </div>
}

export default PremiseCalendar;