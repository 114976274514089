import React, {useState} from 'react';
import Arrow from './../../icons/Arrow';
import Slider from './../Slider/Slider';
import ButtonLinks from './../ButtonLinks/ButtonLinks';

function formatNumberWithCommas(num) {
    return !!num && num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const LeasingAccordionItem = ({suite}) => {
    const [showDetails, setShowDetails] = useState(false);
    const toggleDetails = () => {
        setShowDetails(!showDetails);
    }
    const galleryImages = suite?.suitemedia_set?.map( i => {
        return {
            featured_image: i.media,
            title: '',
            mime: i.mime_type
        }
    })?.filter( i => i.mime.match(/image/i)) || [];
    console.log(galleryImages);
    
    const downloads = suite?.suitemedia_set?.map( i => {
        return {
            url: i.media,
            target: '_blank',
            title: !!i.title ? i.title.replace(/^PLAN_2D/, 'Floor Plan') : 'View PDF', 
            thumbnail: "//crew-quadreal-cc.azurewebsites.net/img/icons/vision.svg",
            mime: i.mime_type
        }
    })?.filter( i => i.mime.match(/pdf/i)) || [];
    return(<li>
        <div className="suite-intro sp-p-md">
            <div onClick={toggleDetails} className={`container show-details-${showDetails}`}>
                {!showDetails 
                ? <React.Fragment>
                    <div style={{display: 'flex'}}>
                        <p style={{width: '300px', marginRight: '60px'}} className="suite-name fs-md td-u fw-bold">Suite {suite.suite_identifier}</p>
                        <p className="sq-ft fw-bold fs-md">{formatNumberWithCommas(suite.area)} sq.ft</p>
                    </div>
                    <Arrow fill={'primary'} width={'22'} />
                </React.Fragment>
                : <React.Fragment>
                    <Arrow flip={true} fill={'primary'} width={'22'} />
                    <p className="suite-name fs-md td-u fw-bold">Suite {suite.suite_identifier}</p>
                </React.Fragment>
                }
                
            </div>
        </div>
        {!!showDetails && <div className="suite-details sp-p-md bg-background container">
            {!!suite.image && <img src={suite.image}/>}
            <p className="fw-bold fs-md sp-m-xs">Key Features</p>
            <div dangerouslySetInnerHTML={{__html: suite.suite_notes}}></div>
            <br/><br/>
            {downloads.length > 0 && <div className="downloads" style={{gridColumn: '1 / span 12'}}>
                <ButtonLinks title="Media" links={downloads} />
            </div>}
            <div className="galleryImages" style={{gridColumn: '1 / span 12'}}>
                <Slider images={galleryImages} />
            </div>
        </div>}
    </li>)
}

export default LeasingAccordionItem