import React, {Fragment, useState, useEffect} from 'react';
import { useForm } from "react-hook-form";
import Cookies from 'js-cookie'
import axios from 'axios';
import createPaths from './apiconstants';
import Login from './coxswainviews/Login.js';
import CoxswainBar from './coxswainviews/CoxswainBar.js';
import PageBar from './coxswainviews/PageBar';
import NestedPageListView from './coxswainviews/NestedPageView';
import ResourceList from './coxswainviews/ResourceList';
import InPageEditor from './coxswainviews/InPageEditor';
import UnitNewsListView from './coxswainviews/UnitListViews';
import './Coxswain.scss';
import AccountView from './coxswainviews/AccountView';
import AddModule from './coxswainviews/AddModule';
import Users from './coxswainviews/Users'

// const flattenSiteMap = (sitemapNested) => {
//   let flat = [];
//   Object.keys(sitemapNested).forEach( key => {
//     let currentPage = _.cloneDeep(sitemapNested[key]);
//     if (!!currentPage) { 
//       currentPage.name = sitemapNested[key].title; 
//       currentPage.crumb = sitemapNested[key].path === 'home' ? "/" : `/${sitemapNested[key].path}`;
//       currentPage.pageTitle = sitemapNested[key].title;
//       flat.push(currentPage) 
//     };
//     if (!!currentPage && currentPage.hasOwnProperty('children')) {
//       Object.keys(currentPage.children).forEach( childkey => {
//         let _currentPage = _.cloneDeep(currentPage.children[childkey]);
//         if (!!_currentPage) { 
//           _currentPage.name = currentPage.children[childkey].title; 
//           _currentPage.crumb = `${currentPage.crumb}/${currentPage.children[childkey].path}`
//           _currentPage.pageTitle = currentPage.children[childkey].title;
//           flat.push(_currentPage) 
//         };
//       });
//     }
//   })
//   return flat;
// }

const Coxswain = (props) => {
  const [loggedIn, setLogin] = useState(false);
  const [view, setView] = useState(false);
  // const [resource, setResource] = useState(null)
  // const [propertyId, setPropertyId] = useState(null);
  // const [siteId, setSiteId] = useState(null);
  const [coxswainEnabled, setCoxswainEnabled] = useState(false);
  const [addModule, setAddModule] = useState(false);
  const siteId = props.site._id
  const propertyId = props.site.parent._id
  const resource = props

  // If Logged in, get all pages, news, stores and site object from API and use that instead of publish state

  useEffect(() => {
    const auth = Cookies.get('CREWAUTH');
    const OPENPROPERTY = Cookies.get('OPENPROPERTY');
    if(!!auth && !!props.site && !!props.site.parent) {
      setLogin(true)
    }
    if (window.location.search == '?admin' || !!auth) {
      setCoxswainEnabled(true);
    }
    if(!!OPENPROPERTY) {
      setView('propertygroup')
      Cookies.remove('OPENPROPERTY')
    }
  }, []);

  const handleLogin = ({token, user}) => {
    Cookies.set('CREWAUTH', token, { expires: 1 })
    delete user.password;
    Cookies.set('CREWUSER', user, { expires: 1 })
    Cookies.set('CREWPROPERTYID', user.propertygroups, { expires: 1 })
    Cookies.set('OPENPROPERTY', true, { expires: 1 })
    window.location.reload();
  }

  const handlelogout = e => {
    !!e && e.preventDefault();
    setLogin(false);
    setView(false);
    Cookies.remove('CREWAUTH');
    Cookies.remove('CREWUSER');
    window.location.reload();
  }

  const handlePublish = e => {
    const auth = Cookies.get('CREWAUTH');
    const path = createPaths(propertyId, siteId).publish;
    axios({
      method: 'POST',
      url: path,
      headers: {
        "Authorization": `Bearer ${auth}`
      }
    }).then( res => {
      console.log(res);
      alert('The site has been published')
    }).catch( err => {
      console.log(err);
    })
  }

  return (<div className="coxswain-reset-tree">
    {!!coxswainEnabled && !!resource && <Fragment>
      <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap" rel="stylesheet"></link>
      {loggedIn 
        ? <CoxswainBar currentView={view} setView={setView} handlelogout={handlelogout} handlePublish={handlePublish} /> 
        : <Login handleLogin={handleLogin} handlelogout={handlelogout} /> }
      {loggedIn && (view === 'editpage' || view === 'seo_meta' || view === 'customscripts' || view === 'customstyles') 
        && <PageBar setView={setView} setShowAddModule={setAddModule} />}
      {loggedIn && view === 'pages' 
        // && <NestedPageListView sitemap={props.sitemap} />}
        && <ResourceList label="Pages" schemaName="Pages" path={createPaths(propertyId, siteId).createPage} data={resource.sitemap} view="bar" sortBy="alphabetical" pages={resource.flatSitemap}/>}
      {loggedIn && (view === 'seo_meta') 
        && <InPageEditor page={props.currentPage} customClass="thirdlevel" description={`<p class="f-sm">
          Enter any custom SEO Tags, Schema types and more here. It will auto inject into the <code class="inline">&lt;head&gt;</code> section of the page. <br/><em>Do note that entering anything here will override CREW CMS's built in SEO.</em>
        </p>`} label="SEO" name={view}/>}
      {loggedIn && view === 'customscripts' 
        && <InPageEditor page={props.currentPage} customClass="thirdlevel"  label="Custom Scripts" name={view}/>}
      {loggedIn && view === 'customstyles' 
        && <InPageEditor page={props.currentPage}  customClass="thirdlevel" label="Custom Styles" name={view}/>}
      {loggedIn && view === 'news' 
        && <ResourceList label="News" schemaName="News" path={createPaths(propertyId, siteId).createNews} data={resource.news} view="bar" sortBy="alphabetical" pages={resource.flatSitemap}/>}
      {loggedIn && view === 'units' 
        && <ResourceList label="Tenant Directory" schemaName="Units" path={createPaths(propertyId, siteId).createStore} data={resource.stores} view="box" sortBy="alphabetical" pages={resource.flatSitemap} />}
      {loggedIn && view === 'account' 
        && <AccountView label="Account" name='Kay' />}
      {loggedIn && view === 'sites' 
      && <InPageEditor page={props.currentPage} label="Site Settings" name={view}/>}
      {loggedIn && view === 'propertygroup' 
      && <ResourceList label="Sites" schemaName="Sites" path={createPaths(propertyId).seednewsite} data={resource.site.parent.sites} view="bar" sortBy="alphabetical" />}
      {!!addModule && !!resource.allowedmodules && <AddModule schema={resource.allowedmodules} currentPage={resource.currentPage} setShowAddModule={setAddModule} />}
      {loggedIn && view === 'users' && <Users label="Users" schemaName="Users"/>}
    </Fragment>}
  </div>)
}

export default Coxswain;