import React, {useState} from 'react';
import { useForm } from "react-hook-form";
import createPaths from './../apiconstants';
import axios from 'axios';
import Cookies from 'js-cookie';

const AccountModule = ({handleModule})=>{
    const { register, getValues, handleSubmit, watch, errors, control, reset } = useForm();
    const user = JSON.parse(Cookies.get('CREWUSER'))
    // on submit, check if values match, then console log 

    const onSubmit = (data) => {
        
        let passwordToChange;
        let doesMatch;

        // matches
        if(data["newPassword"] === data["confirmPassword"]){
                passwordToChange = data["newPassword"];
                doesMatch = true;
        } else {
            doesMatch = false;
        }
        
        // not equal to old password
        let notOldPassword = data["oldPassword"] !== passwordToChange;

        // only send if passwords match and not old password
        if(notOldPassword && doesMatch){
            passwordToChange = data["newPassword"];
            console.log(`send new password ${passwordToChange} to api`)
            const path = createPaths(___appProps.site.parent._id, ___appProps.site._id, ___appProps.currentPage._id, null, user._id).user
            axios({
                method: 'PUT',
                url: path,
                headers: {
                    Authorization: `Bearer ${Cookies.get('CREWAUTH')}`
                },
                data: { 
                    oldPassword: data["oldPassword"],
                    password: passwordToChange
                }
            })
            .then((response) => {
                console.log({response})
                const userData = {
                    email: response.data.data.email,
                    fname: response.data.data.fname,
                    lname: response.data.data.lname,
                    password: response.data.data.password,
                    _id: response.data.data._id
                }
                response.status == 200 && Cookies.set('CREWUSER', userData, { expires: 1/24 })
                window.location.reload()
            })
            .catch((error) => { 
                console.log({error}) 
                alert(error.toString())
            })
        } else if(doesMatch === false){
            console.log(`oops! make sure passwords match`)
        } else if (notOldPassword === false){
            console.log(`oops! please choose new password`)
        }

    }

    return(
        <div className="coxswain coxswain-module-form coxswain-module-new coxswain-reset-tree z-top">
            <div className="coxswain-module-name p-sm text-back bg-fore font-bold">Change your password</div>
            <div className="coxswain-module-container">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="m-bottom-md">
                        <label className="text-fore p-left-sm" htmlFor="oldPassword" >Old Password</label>
                        <input name="oldPassword" ref={register({ required: true })}  type="password" className="border-sm p-md text-fore bg-back"/>
                    </div>
                    <div className="m-bottom-md">
                        <label className="text-fore p-left-sm" htmlFor="newPassword" >New Password</label>
                        <input name="newPassword" ref={register({ required: true })}  type="password" className="border-sm p-md text-fore bg-back"/>
                        
                    </div>
                    <div className="m-bottom-md">
                        <label className="text-fore p-left-sm" htmlFor="confirmPassword" >Confirm Your New Password</label>
                        <input name="confirmPassword" ref={register({ required: "This is required" })}  type="password" className="border-sm p-md text-fore bg-back"/>
                    </div>
                    <div className="coxswain-bottom-buttons bg-fore">
                        <input type="submit" className="p-md bg-fore text-highlight font-bold"/>
                        <span onClick={handleModule} href="" className="text-highlight p-left-md">CANCEL</span>
                    </div>
                </form>
            </div>
        </div>
    )
}

const AccountView = ({label, name}) => {
    const { register, getValues, handleSubmit, watch, errors, control, reset } = useForm();
    const [ isModuleOpen, setModule ] = useState(false);
    const [user, setUser] = useState(JSON.parse(Cookies.get('CREWUSER')))
    
    const handleModule = (e) => {
        e.preventDefault();
        setModule(!isModuleOpen)
    }

    const onSubmit = (data) => {
        const path = createPaths(___appProps.site.parent._id, ___appProps.site._id, ___appProps.currentPage._id, null, user._id).user
        axios({
            method: 'PUT',
            url: path,
            headers: {
              Authorization: `Bearer ${Cookies.get('CREWAUTH')}`
            },
            data: data
        })
        .then((response) => {
            console.log({response})
            const userData = {
                email: response.data.data.email,
                fname: response.data.data.fname,
                lname: response.data.data.lname,
                password: response.data.data.password,
                _id: response.data.data._id
            }
            response.status == 200 && Cookies.set('CREWUSER', userData, { expires: 1/24 })
            window.location.reload()
        })
        .catch((error) => {})
    }

    // console.log(account);
    return(<div className="--coxswain-resources bg-back p-md z-base">
        <div className="resources__top flex-row-space flex-align-center">
            <h3 className="text-fore">{label}.</h3>
        </div>
        <div className="coxswain-module-container">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <div className="m-bottom-md">
                        <label className="text-fore p-left-sm" htmlFor="fname" >First Name</label>
                        <input name="fname" defaultValue={user.fname} ref={register({ required: true })}  type="text" className="border-sm p-md text-fore bg-back"/>
                    </div>
                    <div className="m-bottom-md">
                        <label className="text-fore p-left-sm" htmlFor="lname" >Last Name</label>
                        <input name="lname" defaultValue={user.lname} ref={register({ required: true })}  type="text" className="border-sm p-md text-fore bg-back"/>
                    </div>
                    <div className="coxswain-bottom-buttons bg-fore">
                        <input type="submit" className="p-md bg-fore text-highlight font-bold"/>
                    </div>
                </div>
            </form>
        </div>
        <ul className="account-actions m-top-md m-bottom-md flex-row-start flex-align-center">
            <li>
                <a href="#" className="text-fore font-bold hover-text-highlight p-sm block border-sm" onClick={handleModule}>Change Password</a>
            </li>
        </ul>
        { isModuleOpen ? <AccountModule handleModule={handleModule}/> : null}
    </div>)
}

export default AccountView;