import React from 'react';
import MenuList from './MenuList';
import Arrow from './../../icons/Arrow'

const MenuItem = ({item, pushItems, includeChildren, parentItem, overrideChildClick}) => {   
    const Tag = !!item.children ? `li` : `span`;
    
    const showChildren = (e, items) => { 
        if (!!items && !!items.length && window.innerWidth < 768) {
            e.preventDefault();
            !!pushItems && pushItems(items, item);
        }
    }

    let slug = `${item.path.charAt(0) != '/' ? "/" : ""}${item.path}`;

    if (item.path == "home") {
        slug = '/'
    }
    if (!!parentItem) {
        let prefix = parentItem.path.charAt(0) != '/' ? '/' : "";
        let checkItemPath = item.path.indexOf(parentItem.path)
        slug = checkItemPath > -1 && checkItemPath < 2 ? item.path : `${prefix}${parentItem.path}/${item.path}`
    }
    if (!!item.slug && (item.slug.indexOf('https://') > -1 || item.slug.indexOf('http://') > -1)) {
        slug = item.path;
    }

    const RenderChildren = ({item, includeChildren}) => {
        let tmpParent = Object.assign({}, item) // To make sure this process will not effect the original sitemap. 
        tmpParent.path = !!parentItem ? `${parentItem.path}/${tmpParent.path}` : tmpParent.path
        return <MenuList parentItem={tmpParent} items={item.children} pushChildren={false} includeChildren={includeChildren}/>
    }
    
    let href = item.page_redirect || slug;
    href =  href == '/building/sustainability' ? 'https://www.quadreal.com/esg/our-esg-approach/' : href
    
    return <Tag>
        <a onClick={(e) => {
            e.preventDefault(); 
        }} className={!!item.children ? 'has-children' : ''} href={href}>
            {!!item.icon ? <img className="menu-icon" src={item.icon} alt={item.title} /> : 
            <span onClick={(e) => {
                e.preventDefault(); 
                window.location.href= href;
            }}>{item.title}</span>
            }
            {!!item.children && !!item.children.length &&<span onClick={(e) => {
                e.preventDefault(); 
                if (!!item.children && !!item.children.length) {
                    item.path = href;
                    showChildren(e, item.children)
                } else {
                    window.location.href= href;
                }
                if (!!overrideChildClick) {
                    window.location.href= href;
                }
            }}> <Arrow /></span>}
        </a>
        {!!includeChildren && !!item.children && <RenderChildren item={item} includeChildren={includeChildren}/>}
    </Tag>
}

export default MenuItem;