import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const DragAndDropResourceList = ({ data, parent, parentId, parentIndex, handleReorder=(r) => {
    console.log('trying to set new data', r);
} }) => {

    return (<DragDropContext onDragEnd={result => {
            const { source, destination, draggableId } = result;
            handleReorder({
                ...result,
                parentId,
                parent,
                parentIndex
            })
            
        }}>
            <Droppable droppableId={`resource-${parentId}`} type="RESOURCELISTING">
            {(provided, snapshot) => (
                    <div className={`mb-10`} ref={provided.innerRef}>
                        {data.map((item, index) => (
                            <Draggable
                                key={item._id}
                                draggableId={'' + item._id}
                                className="resource__item"
                                style={{display: 'block'}}
                                index={index}>
                                {(provided, snapshot) => (
                                    <div
                                        className={`resource__item_inner block border-sm-light m-bottom-sm ${!!item.children ? 'p-sm' : ''}`}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        <h4 className="resource__title p-sm text-fore font-bold relaxes font-base">{item.title}</h4>
                                        {!!item.children && <DragAndDropResourceList data={item.children} handleReorder={handleReorder} parentIndex={`${parentIndex}[${index}]`} parentId={item._id} parent={item} />}
                                    </div>
                                )}
                            </Draggable>
                        ))}
                    {provided.placeholder}
                </div>
            )}
            </Droppable>
        </DragDropContext>)
}

export default DragAndDropResourceList;