import React, { useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import createPaths from '../../coxswain/apiconstants';
import axios from 'axios';
import Cookies from 'js-cookie';

const ChangeParent = ({ componentItemData, changeParent, pages, parentItem, hasParent, onSubmited = () => {} }) => {
    const { register, getValues, handleSubmit, watch, errors, control, reset } = useForm();

    const onSubmit = (data) => {
        const currentParent = hasParent ? `/${parentItem.path}` : `/${componentItemData.path}`;
        const newParent = watch();
        const hasChanged = Object.keys(newParent)
                            .map( key => newParent[key] === currentParent)
                            .filter( bool => !bool ).length > 0;
        
        if(hasChanged) {
            // // go through pages and find the ID of the new parent 
            let newParentId = pages.filter((page, id) => {
                // if its home use different value to search
                let toSearch = newParent[componentItemData.path] === '/home' ? `/${page.path}` : page.crumb;

                if(toSearch === newParent[componentItemData.path]){
                    return page;
                }
            }).map( parent => parent._id);

            const currentPageID = componentItemData._id;
            const path = createPaths(___appProps.site.parent._id, ___appProps.site._id, currentPageID).updatePage;
            const parentID = !!newParentId && !!newParentId.length ? (newParentId[0] != currentPageID ? newParentId[0] : null) : null
            axios({
                method:'PUT',
                url: path,
                headers: {
                    "Authorization": `Bearer ${Cookies.get('CREWAUTH')}`
                },
                data: {
                    parent: parentID
                }
            }).then(res => {
                window.location.reload()
            }).catch(err => {
                console.log({err})
            })
            console.log({componentItemData})
            console.log(`change the parent of ${componentItemData.title} to:`, newParentId[0]);
        }
    }

    // console.log(newParent);
    // console.log(componentItemData);
    // console.log(pages);

    return (
        <div className="coxswain coxswain-module-form coxswain-module-new coxswain-reset-tree z-top">
                <div className="coxswain-module-name p-sm text-back bg-fore font-bold">Change Parent</div>
                <div className="coxswain-module-container">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="coxswain-inputs">
                        <label className="p-left-sm text-fore">Change the parent</label>
                            <select ref={register} defaultValue={hasParent ? `/${parentItem.path}` : `/${componentItemData.path}`} name={componentItemData.path} className="border-sm p-md text-fore bg-back m-bottom-md">
                                <option value="" hidden>Select Parent</option>
                                <option value="0">No Parent</option>
                                {
                                    !!pages && pages.map((option, idx) => (
                                        <option value={option.path === 'home' ? `/${option.path}` : option.crumb} key={idx}>{option.path === 'home' ? `/${option.path}` : option.crumb}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="coxswain-bottom-buttons bg-fore">
                            <span onClick={changeParent} href="" className="text-highlight p-left-md">CANCEL</span>
                            <input type="submit" className="p-md bg-fore text-highlight font-bold"/>
                        </div>
                    </form>
                </div>
        </div>
    );
}

export default ChangeParent;