import React from 'react';

const IconText = ({thumbnail, content, title, alignment, space_after="sm"}) => {
  return (<section className={`icontext container align-${alignment} sp-m-${space_after}`}>
    <figure className="icontext_image">
      {!!thumbnail && <img src={thumbnail} alt=""/>}
    </figure>
    <div className="icontext_content">
      <p><strong>{title}</strong></p>
      {!!content && <div dangerouslySetInnerHTML={{__html: content}} />}
    </div>
  </section>)
}

export default IconText;