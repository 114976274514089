import React, {useEffect, useState} from 'react';
import backFilledVars from './../../helpers/backFilledVars';
import vars from './../AppVars.json';
import _ from 'lodash';

let layout = {};
Object.keys(vars).map(breakpoint => {
    Object.keys(vars[breakpoint])
            .filter( variableName => variableName.indexOf('--layout-ratio-') == 0)
            .map( variableName => {
                layout[`${variableName}`] = layout[`${variableName}`] || {};
                layout[`${variableName}`][parseInt(breakpoint)] = vars[breakpoint][variableName];
            });
});

const findBreakpointIndex = (initialBreakpoints, windowWidth) => {
    let breakpointIndex = 0;
    initialBreakpoints.map((breakpoint, index) => {
        if (!!initialBreakpoints[index + 1] && windowWidth > breakpoint && windowWidth < initialBreakpoints[index + 1]) {
            breakpointIndex = index;
        } else if (!!!initialBreakpoints[index + 1] && windowWidth > breakpoint) {
            breakpointIndex = index;
        }
    });
    return {breakpoint: initialBreakpoints[breakpointIndex], index: breakpointIndex};
}

const getLayoutRatios = (cardItem, windowWidth) => {    
    // get the correct varible by ratioKey from layout and call it current layoot ratios
    // find the appropriate breakpoint (basically closest less than windowwindow)
    const currentLayoutRatios = !!cardItem.ratioKey ? layout[`--layout-ratio-${cardItem.ratioKey}`] : layout[`--layout-ratio-default`];
    const currentBreakpointData = findBreakpointIndex(Object.keys(currentLayoutRatios), windowWidth);
    const currentBreakpoint = currentBreakpointData.breakpoint;
    const currentBreakpointIndex = currentBreakpointData.index;
    const currentRatio = currentLayoutRatios[currentBreakpoint];
    const currentRatioSpans = currentRatio.split(":")
    const gridSpan = currentRatioSpans.reduce((i,x) => parseInt(i)+parseInt(x));
    const contain = cardItem.contain;

    // reconstruct items for current breakpoint and fill with spacers if the colspan does not meet certain criteria
    let currentLayoutThreshold = !!backFilledVars && Object.values(backFilledVars[currentBreakpointIndex].filter((property) => Object.keys(property) == '--layout-fill-threshold')[0]);
    let layoutThreshold = Object.values(currentLayoutThreshold)[0];
    let reconstitutedItems = [];
    let currentItem = 0;
    let currentCol = 0;

    let spacerComponent = {
        name: 'Spacer',
        type: 'Spacer',
        componentSchema: false,
        items: false,
        ratioKey: false,
        props: false,
    }

    while (currentItem < cardItem.items.length) {
        // const invalidThreshold = currentRatioSpans.filter((span) => span >= layoutThreshold).length > 0;
        // if (!!invalidThreshold) {
        //     currentItem = 10000;
        // }
        const thresholdCondition = currentRatioSpans[currentCol%currentRatioSpans.length] >= layoutThreshold;
        reconstitutedItems.push(thresholdCondition ? cardItem.items[currentItem] : spacerComponent);
        currentItem = currentItem + (thresholdCondition ? 1 : 0);
        currentCol++;
    }

    return {
        items: _.chunk(reconstitutedItems, currentRatioSpans.length),
        gridSpan,
        currentRatioSpans,
        contain
    }
}

export default getLayoutRatios;