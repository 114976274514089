var baseUrl = `https://crewcms-api.azurewebsites.net/` // PROD URL -> DO NOT USE!!!
// var baseUrl = `http://localhost:3000`;

const createPaths = (propertyId, siteId, pageId, resourceId, userId) => {
  return {
    signIn: `${baseUrl}/signin`,
    forgotPassword: `${baseUrl}/forgot-password`,
    userListing: `${baseUrl}/api/user/`,
    createUser: `${baseUrl}/api/user/`,
    singleUser: `${baseUrl}/api/user/${userId}`,
    user: `${baseUrl}/api/user/${userId}`,
    sites: `${baseUrl}/api/propertygroup/${propertyId}/site`,
    cloneSite: `${baseUrl}/api/clone/propertygroup/${propertyId}`,
    seednewsite: `${baseUrl}/api/seednewsite/${propertyId}`,
    updateSite: `${baseUrl}/api/propertygroup/${propertyId}/site/${siteId}`,
    createPage: `${baseUrl}/api/propertygroup/${propertyId}/site/${siteId}/page`,
    updatePage: `${baseUrl}/api/propertygroup/${propertyId}/site/${siteId}/page/${pageId}`,
    listPage: `${baseUrl}/api/propertygroup/${propertyId}/site/${siteId}/page`,
    singlePage: `${baseUrl}/api/propertygroup/${propertyId}/site/${siteId}/page/${pageId}`,
    createModule: `${baseUrl}/api/propertygroup/${propertyId}/site/${siteId}/page/${pageId}/pagemodule`,
    updateModule: `${baseUrl}/api/propertygroup/${propertyId}/site/${siteId}/page/${pageId}/pagemodule/${resourceId}`,
    listModule: `${baseUrl}/api/propertygroup/${propertyId}/site/${siteId}/page/${pageId}/pagemodule`,
    singleModule: `${baseUrl}/api/propertygroup/${propertyId}/site/${siteId}/page/${pageId}/pagemodule/${resourceId}`,
    getPage: `${baseUrl}/api/propertygroup/${propertyId}/site/${siteId}/page/${pageId}?dump=true`,
    createStore: `${baseUrl}/api/propertygroup/${propertyId}/site/${siteId}/store`,
    listStore: `${baseUrl}/api/propertygroup/${propertyId}/site/${siteId}/store`,
    singleStore: `${baseUrl}/api/propertygroup/${propertyId}/site/${siteId}/store/${resourceId}`,
    createNews: `${baseUrl}/api/propertygroup/${propertyId}/site/${siteId}/newsitem`,
    listNews: `${baseUrl}/api/propertygroup/${propertyId}/site/${siteId}/newsitem`,
    singleNews: `${baseUrl}/api/propertygroup/${propertyId}/site/${siteId}/newsitem/${resourceId}`,
    getDevelopmentResource: `${baseUrl}/api/publish/${siteId}?development=true`,
    upload: `${baseUrl}/api/storage/${siteId}`,
    publish: `${baseUrl}/api/publish/${siteId}`,
    reorderPages: `${baseUrl}/api/site/${siteId}/pages/reorder`,
  }
}

export default createPaths;