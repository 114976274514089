import React from 'react';
import CommerceCourt from './../../icons/CommerceCourt.svg';
import SearchToggle from './SearchToggle';
import Hamburger from './../../icons/Hamburger';
import Close from './../../icons/Close';
import { replaceAssetsURL } from '../../helpers/helpers';

const MobileTopNav = ({toggleSearchMode, toggleMenuOpen, searchMode, menuOpen, site}) => {
    return(<nav className="mobile-top-nav">
        <button className={`menu-toggle open-${menuOpen}`} onClick={toggleMenuOpen}>
            {!menuOpen ? <Hamburger fill={'background'}/> : <Close fill={'background'}/>}
        </button>
        <a className="site-logo" href="/">
            <img src={replaceAssetsURL(site.logo, '?w=160')} alt={site.title}/>
        </a>
        <SearchToggle toggleSearchMode={toggleSearchMode} searchMode={searchMode} />
    </nav>)
}

export default MobileTopNav;