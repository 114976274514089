import React from 'react';

const Search = ({fill='tertiary'}) => {
	return(<React.Fragment>
        <svg fill={`var(--c-${fill})`} width="17px" height="17px" viewBox="0 0 17 17">
            <path d="M16.3862681,15.408922 L11.6688279,10.6914819 C12.5826052,9.56276307 13.1327896,8.12851223 13.1327896,6.56641098 C13.1327896,2.94590528 10.1868843,0 6.56637858,0 C2.94587288,0 0,2.94587288 0,6.56637858 C0,10.1868843 2.94590528,13.1327896 6.56641098,13.1327896 C8.12851223,13.1327896 9.56276307,12.5826052 10.6914819,11.6688279 L15.408922,16.3862681 C15.543706,16.5210521 15.7206424,16.5888005 15.8976112,16.5888005 C16.0745801,16.5888005 16.2515165,16.5210521 16.3863005,16.3862681 C16.6565489,16.1160197 16.6565489,15.6791704 16.3862681,15.408922 Z M6.56641098,11.7503787 C3.7075969,11.7503787 1.38241084,9.42519267 1.38241084,6.56637858 C1.38241084,3.7075645 3.7075969,1.38237844 6.56641098,1.38237844 C9.42522507,1.38237844 11.7504111,3.7075645 11.7504111,6.56637858 C11.7504111,9.42519267 9.42519267,11.7503787 6.56641098,11.7503787 Z"></path>
        </svg>
	</React.Fragment>)
}

export default Search;