import React from 'react';
import Search from '../../icons/Search';
import Close from '../../icons/Close';

const SearchToggle = ({toggleSearchMode, searchMode}) => {
    return(<button onClick={toggleSearchMode} className={`search-toggle active-${searchMode}`}>
        {!!searchMode ? <Close fill={'background'}/> : <Search fill={'background'}/>}
    </button>)
}

export default SearchToggle;
