import React from 'react';
import vars from './AppVars.json';
import _ from 'lodash';
import { Helmet } from 'react-helmet';

const AppVars = () => {
    let varArray = Object.keys(vars).map((key) => {
        return {
            breakpoint: key,
            vars: vars[key]
        }
    })
    return(<Helmet>
        <style type="text/css">{`
            ${varArray.map((bp) => {
                return(`@media(min-width: ${bp.breakpoint}) {
                    :root {
                        ${Object.keys(bp.vars).map((key, i) => {
                            return `${key}: ${bp.vars[key]};`
                        }).filter( i => i).join('')}
                    }
                }`)
            }).filter( i => i).join('')}}
        `}</style>
    </Helmet>);
}

export default AppVars;