import React from 'react';

const ButtonLinks = ({title, links, space_after="lg"}) => (<section className={`container sp-m-${space_after} button_links`}>
    {!!title && <div className="button_links_inner"><h2>{title}</h2></div>}
    <div className="button_links_inner">
        {!!links && links.map( (link, idx) => !!link && !!link.url && (<a key={idx} className="buttonlink" target={link.target} href={link.url} download={link.url.indexOf('.pdf') > -1 ? link.url : false}>
            {!!link.thumbnail && <img src={link.thumbnail} alt={link.title} />}
            <span className="buttonlinktitle">{link.title}</span>
        </a>))}
    </div>
</section>)

export default ButtonLinks;