import React from 'react';

const Heading = ({
  level=2,
  content,
  className="",
  lineEmphasis=false,
  blockEmphasis=false,
  jumplink=false,
  hide=false
}) => {
  const Tag = `h${level}`;
  const jumplinkID = (jumplink) => {
    return jumplink.split(' ').join('-').toLowerCase();
  }
  return (<Tag
    id={!!jumplink ? jumplinkID(jumplink) : null}
    data-jumplink={!!jumplink ? jumplink : null}
    className={`heading ${className} hide-${hide} lineEmphasis-${lineEmphasis} ${!!blockEmphasis ? `blockEmphasis blockEmphasis-${blockEmphasis}` : null}`}>
    {content}
  </Tag>)
}

export default Heading;