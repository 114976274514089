import React, { useState } from 'react';
import Image from './../Image/Image';
import Heading from './../Heading/Heading';

const FlipCard = ({card}) => {
    const [flipped, setFlipped] = useState(false);
    return <article className={`flip-card flipped-${flipped}`} 
        onMouseOver={(e) => setFlipped(true)} 
        onMouseOut={(e) => setFlipped(false)}
        // onClick={(e) => setFlipped(!flipped)}
        >
        <div className="card-content-wrapper">
            <a href={card.url || "#"}>
                <div className="card-front bg-primary">
                    <div className="inner-content-wrapper">
                        {!!card.thumbnail && <Image className="sp-m-md" src={card.thumbnail} alt={card.title}/>}
                        {!!card.title && <Heading level={6} className={"c-background fs-md"} content={card.title}/>}
                    </div>
                </div>
                <div className="card-back bg-foreground">
                    <div className="inner-content-wrapper" style={{backgroundImage: !!card.thumbnail ? 'url(' + card.thumbnail + ')' : null}}>
                        {!!card.content && <div className="c-background card-content" dangerouslySetInnerHTML={{__html: card.content}}></div>}
                    </div>
                </div>
            </a>
        </div>
    </article>
}

export default FlipCard;