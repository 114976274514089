import React from 'react';
import MenuItem from './MenuItem';
import { orderedPages } from '../../helpers/helpers';

const MenuList = ({items, pushItems, includeChildren=false, parentItem, overrideChildClick=false}) => {
    items = orderedPages(Object.keys(items).map(k => items[k]))
    return <nav><ul>
        {/* {Object.keys(items).map((menu, i) => {
            console.log({menu})
            Object.values(items)[i].slug = menu;
            return !!Object.values(items)[i].title && <MenuItem overrideChildClick={overrideChildClick} includeChildren={includeChildren} pushItems={pushItems} key={i} parentItem={parentItem} item={Object.values(items)[i]}/>
        })} */}
        {items.map((item, i) => !!item.title && <MenuItem overrideChildClick={overrideChildClick} includeChildren={includeChildren} pushItems={pushItems} key={i} parentItem={parentItem} item={item}/>)}
    </ul></nav>
}

export default MenuList;