import React, { useState, useEffect } from 'react';
import DragAndDropResourceList from './DragAndDropResourceList';
import createPaths from './../apiconstants';
import _, { cloneDeep, set, get } from 'lodash';
import axios from 'axios';
import Cookies from 'js-cookie'

const flattenObject = (obj, prefix = '') =>
    !!obj ? Object.keys(obj).reduce((acc, k) => {
        const pre = prefix.length ? prefix + '.' : '';
        if (typeof obj[k] === 'object') Object.assign(acc, flattenObject(obj[k], pre + k));
        else acc[pre + k] = obj[k];
        return acc;
    }, {}) : false;

const sanitizeAndFlattenObject = (data) => {
    const resourceList = flattenObject(data);
    Object.keys(resourceList).forEach( key => {
        if (
            key.includes('contentZone') ||
            key.includes('props') ||
            key.includes('custom') 
            ) {
            delete resourceList[key]
        }
    });
    return resourceList;
}

const generateOrder = (data) => {
    let resourceListFlat = sanitizeAndFlattenObject(data);
    const newOrder = Object.keys(resourceListFlat)
            .filter( key => key.indexOf('_id') > -1)
            .map( key => resourceListFlat[key]);
    return newOrder
}


const getObjectPathFromId = (id, list) => {
    let result = "";
    !!list && Object.keys(list).forEach( key => {
        if (key.includes('_id') && list[key] === id) {
            result = key.replace("._id", '');
        }
    })
    return result;
}

const DropAndDropResourceWrapper = ({ initialData }) => {
    const [data, setData] = useState(initialData);

    const handleReorder = ({ source, destination, draggableId, parent }) => {
        console.log({ source, destination});
        let sourceId = source?.droppableId?.replace(/resource-/g, '');
        let destinationId = destination?.droppableId?.replace(/resource-/g, '');

        let _data = cloneDeep(data);
        let resourceListFlat = sanitizeAndFlattenObject(data);
        let theItemsCurrentPath = getObjectPathFromId(draggableId, resourceListFlat);
        let theSourcePath = getObjectPathFromId(sourceId, resourceListFlat);
        let theDestinationPath = getObjectPathFromId(destinationId, resourceListFlat);

        let theItem = cloneDeep(get(_data, theItemsCurrentPath));

        if (theSourcePath === theDestinationPath) {
            if (!theSourcePath) {
                // remove the source
                _data = _data.filter( (i, idx) => idx !== source.index );
                // // insert the  item
                _data.splice(destination.index, 0, theItem);
                // [_data[source.index], _data[destination.index]] = [_data[destination.index], _data[source.index]]
                setData(_data);
            } else {
                let theParentArrayPath = theSourcePath + '.children';
                let _theParentArray = get(_data, theParentArrayPath);
                _theParentArray = _theParentArray.filter( (i, idx) => idx !== source.index );
                _theParentArray.splice(destination.index, 0, theItem);
                set(_data, theParentArrayPath, _theParentArray);
                setData(_data);
            }
        }
    }

    const handleSave = () => {
        
            const newOrder = generateOrder(data);
            const path = createPaths(___appProps.site.parent._id, ___appProps.site._id,  ___appProps.currentPage._id).reorderPages;
            var formData = {
                order: newOrder,
            }
            console.log(newOrder);
            axios({
                method: 'POST',
                url: path,
                headers: {
                    "Authorization": `Bearer ${Cookies.get('CREWAUTH')}`
                },
                data: formData
            }).then(res => {
                window.location.reload()
            }).catch(err => {
                console.log({err})
            });
    }

    return <>
        <button className="font-sm p-md bg-white text-upper font-bold text-highlight border-sm  hover-text-highlight hover-bg-fore" onClick={e => handleSave()}>SAVE ORDER</button>
        <DragAndDropResourceList data={data} parent={false} parentIndex="" parentId={null} handleReorder={handleReorder} />
    </>
}

export default DropAndDropResourceWrapper;