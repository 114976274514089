import axios from 'axios';
import React, {useState, useEffect} from 'react';
import { useForm, Controller } from "react-hook-form";
import Dropzone from 'react-dropzone'
import createPaths from './../apiconstants';
import Cookies from 'js-cookie';

const InPageEditor = ({label, name, description, customClass, page}) => {
  const [siteLogo, setSiteLogo] = useState(window.___appProps.site.logo || null);
  const [footerLogo, setFooterLogo] = useState(window.___appProps.site.footerLogo || null);
  const { register, handleSubmit, watch, errors, control } = useForm();
  const pageId = window.___appProps.currentPage._id;
  const site = window.___appProps.site;
  let path = createPaths(site.parent._id, site._id, pageId).updatePage;

  const onSubmit = data => {
    console.log(data);
    axios({
      method: 'put',
      url: path,
      headers: {
        Authorization: `Bearer ${Cookies.get('CREWAUTH')}`
      },
      data: data
    })
    .then((response) => {
      console.log({response})
    })
    .catch((error) => {})
  };

  const onSubmitSites  = (data) => {
    console.log(data);
    const path = createPaths(___appProps.site.parent._id, ___appProps.site._id).updateSite;
    axios({
      method: "PUT",
      url: path,
      headers: {
        "Authorization": `Bearer ${Cookies.get('CREWAUTH')}`
      },
      data: data
    }).then(res => {
      console.log({res})
      window.location.reload()
    }).catch(err => {
      console.log({err})
    })
  }

  const handleDrop = (acceptedFiles, name='image', setItemInComponent, onChange) => {
    console.log(acceptedFiles[0]);
    var formData = new FormData();
    formData.append('image', acceptedFiles[0], acceptedFiles[0].name);
    const path = createPaths(___appProps.site.parent._id, ___appProps.site._id).upload;
    return axios.post(path, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        "Authorization": `Bearer ${Cookies.get('CREWAUTH')}`
      }
    }).then(res => {
      console.log(res.data.filepath)
      setItemInComponent(res.data.filepath);
      onChange(res.data.filepath);
    })
    .catch( err => console.log(err));
  }

  // console.log(site);

  return (<div className={`coxswain coxswain-bar coxswain-bar-block coxswain-editpages-view ${customClass}`}>
    <h3>{label}</h3>
    <div className="description" dangerouslySetInnerHTML={{__html: description}} />
    <br />
    { label === 'Site Settings' ? 
      <form onSubmit={handleSubmit(onSubmitSites)}>
          <div>
            <label className="p-left-sm text-fore" htmlFor={site.title}>Site Name</label>
            <input className="p-sm border-sm m-bottom-md text-fore" type="text" name="title" id={site.title} defaultValue={site.title} style={{width: '50%'}} ref={register}/>
            <label className="p-left-sm text-fore" htmlFor="googleAnalyticsCode">Google Tag ID</label>
            <input className="p-sm border-sm m-bottom-md" name="googleAnalyticsCode" id="googleAnalyticsCode" defaultValue={site.googleAnalyticsCode} placeholder="Google Analytics" style={{ width: '50%'}} ref={register}/>
            <label className="p-left-sm text-fore" htmlFor="siteLogo">Site logo</label>
            <div className="coxswain-dropzone border-sm p-md flex-align-center" style={{display: 'flex', width: '50%'}}>
                  {!!site.logo && <figure style={{backgroundColor: 'grey'}}><img src={siteLogo} /></figure>}
                <Controller
                  name='logo'
                  control={control}
                  rules={{ required: false }}
                  defaultValue={site.logo}
                  render={props =>
                    <Dropzone multiple={false} accept="image/png, image/jpeg, video/*" onDrop={acceptedFiles => {handleDrop(acceptedFiles, 'image', setSiteLogo, props.onChange)} }>
                    {({getRootProps, getInputProps}) => (
                      <section>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <p>Drag and drop a media item here, or click to select a file</p>
                        </div>
                      </section>
                    )}
                  </Dropzone>}
                />
            </div>
            <label className="p-left-sm text-fore" htmlFor="siteLogo">Footer logo</label>
            <div className="coxswain-dropzone border-sm p-md flex-align-center" style={{display: 'flex', width: '50%'}}>
                {!!site.footerLogo && <figure style={{backgroundColor: 'grey'}}><img src={footerLogo} /></figure>}
                <Controller
                  name='footerLogo'
                  control={control}
                  rules={{ required: false }}
                  defaultValue={site.footerLogo}
                  render={props =>
                    <Dropzone multiple={false} accept="image/png, image/jpeg, video/*" onDrop={acceptedFiles => {handleDrop(acceptedFiles, 'image', setFooterLogo, props.onChange)} }>
                    {({getRootProps, getInputProps}) => (
                      <section>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <p>Drag and drop a media item here, or click to select a file</p>
                        </div>
                      </section>
                    )}
                  </Dropzone>}
                />
            </div>
            <label className="p-left-sm text-fore" htmlFor={site.title}>Property Address</label>
            <textarea style={{width: '50%'}} className="p-sm border-sm m-bottom-md" name="address" id="address" cols="20" rows="10" ref={register}>
            {site.address}
            </textarea>
            <br/>
            <hr/>
            <br/>
            <h3>Social Settings</h3>
            <label className="p-left-sm text-fore" htmlFor={site.title}>Facebook</label>
            <input className="p-sm border-sm m-bottom-md text-fore" type="text" name="facebook" id={site.title} defaultValue={site.facebook} style={{width: '50%'}} ref={register}/>
            <label className="p-left-sm text-fore" htmlFor={site.title}>Twitter</label>
            <input className="p-sm border-sm m-bottom-md text-fore" type="text" name="twitter" id={site.title} defaultValue={site.twitter} style={{width: '50%'}} ref={register}/>
            <label className="p-left-sm text-fore" htmlFor={site.title}>LinkedIn</label>
            <input className="p-sm border-sm m-bottom-md text-fore" type="text" name="linkedin" id={site.title} defaultValue={site.linkedin} style={{width: '50%'}} ref={register}/>
            <label className="p-left-sm text-fore" htmlFor={site.title}>Instagram</label>
            <input className="p-sm border-sm m-bottom-md text-fore" type="text" name="instagram" id={site.title} defaultValue={site.instagram} style={{width: '50%'}} ref={register}/>
            <label className="p-left-sm text-fore" htmlFor={site.title}>Mailchimp Newsletter ID</label>
            <input className="p-sm border-sm m-bottom-md text-fore" type="text" name="mailchimp" id={site.title} defaultValue={site.mailchimp} style={{width: '50%'}} ref={register}/>
            <input className="p-md bg-fore text-highlight font-bold m-top-md" type="submit" />
          </div>
      </form>
        :
      <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <textarea className="p-sm border-sm m-bottom-md" name={name} placeholder={label} ref={register({ required: true })}>
              {label === 'Custom Scripts' && !!page.customscripts ? decodeURIComponent(page.customscripts) : label === 'Custom Styles' && !!page.customstyles ? decodeURIComponent(page.customstyles) : label === 'SEO' && !!page.seo_meta ? decodeURIComponent(page.seo_meta) : ""}
            </textarea>
            <input className="font-bold" type="submit" />
          </div>
      </form>
    }
      
  </div>)
}

export default InPageEditor