import React from 'react';
import _ from 'lodash';

const PageTitle = ({title, featured_image, featured_video, appProps}) => {
  // console.log(appProps.currentPage);
  return (<section className="pagetitle sp-p-super sp-m-lg">
    <div className="container with-left-highlight">
      <h1>{title}</h1>
      <ul className="pagetitle_crumbs">
        <li><a href="/">Home</a></li>
        {appProps.currentPage.crumb.split("/").filter(x => !!x).map((x,i) => <li key={i}><a href={`/${x}`}>{_.startCase(_.toLower(x))}</a></li>)}
      </ul>
    </div>
    <figure className={`page_title_image with-gradient`}>
        {!!featured_video ? <video muted autoPlay={true} loop={true} src={featured_video}></video> : !!featured_image ? <img src={featured_image} alt={title}/> : false}
    </figure>
  </section>
  )
}


export default PageTitle;