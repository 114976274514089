import React from 'React';
import Slider from 'react-slick';
import './RelatedLinks.scss';
import { replaceAssetsURL } from '../../helpers/helpers';

const RelatedLinks = ({title, background='false', links, image_view="featured_image", space_after="null"}) => {
    const sliderSettings = {
        dots: true,
        infinite: false,
        arrows: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    centerMode: false,
                    infinite: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },{
                breakpoint: 567,
                settings: {
                    centerMode: false,
                    infinite: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return<div className={`related-links sp-m-${space_after} background-${background} image-view-${image_view}`}>
        <div className="grid outer-container">
            <div className="offset"></div>
            <div className="related-links-inner-wrapper">
                <div className="grid">
                    {!!title && <h2 className="listing-title c-primary">{title}</h2>}
                </div>
                <div className="slider-container">
                    <Slider {...sliderSettings}>
                        {!!links && links.map((link,i) => {
                            return <article key={i}>
                                <a href={link.url || ''} download={!!link.url && link.url.indexOf('.pdf') > -1 ? link.url : false}>
                                    {image_view == "featured_image" && !!link.featured_image ? <img className="featured-image" src={replaceAssetsURL(link.featured_image, '?w=260')} alt={link.title}/> :
                                    image_view == "thumbnail" && !!link.thumbnail ? <img className="thumbnail" src={replaceAssetsURL(link.thumbnail, '?w=260')} alt={link.title}/> :
                                    <div className="placeholder-background bg-foreground"></div> }
                                    <h3 className={image_view == "featured_image" ? 'fs-md c-background ff-b' : 'fs-md c-foreground ff-h'}>{link.title}</h3>
                                </a>
                            </article>
                        })}
                    </Slider>
                </div>
            </div>
        </div>
    </div>
}

export default RelatedLinks;