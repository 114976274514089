const defaultFieldSchemas = {};
defaultFieldSchemas.title = {
    "name": "title",
    "displayName": "Title",
    "displayDescription": "Leave empty if you want this to not show",
    "type": "input"
};
defaultFieldSchemas.featured_embed = {
    "name": "featured_embed",
    "displayName": "Featured Embed",
    "displayDescription": "Please insert the url of the iframed map or youtube video",
    "type": "input"
};
defaultFieldSchemas.content = {
    "name": "content",
    "type": "richtext",
    "displayName": "Content"
};
defaultFieldSchemas.tags = {
    "name": "tags",
    "displayName": "Tags",
    "displayDescription": "Enter comma separated text for tags you want to display",
    "type": "stringarray"
}
defaultFieldSchemas.link = {
    "name": "link",
    "type": "zone",
    "displayName": "Link",
    "zoneSchema": [
        defaultFieldSchemas.title,
        {
            "name": "url",
            "displayName": "URL",
            "displayDescription": "Leave empty if you want this to not show",
            "type": "url"
        },
        {
            "name": "target",
            "displayName": "Does this link open in a new window?",
            "type": "choice",
            "options": [{
                "displayName": "Yes. Open in a new window.",
                "value": "_blank"
            }, {
                "displayName": "No. Open in the same window.",
                "value": ""
            }]
        }
    ]
}
defaultFieldSchemas.links = {
    "name": "links",
    "type": "repeater",
    "repeaterDisplayKey": "title",
    "repeaterSchema": defaultFieldSchemas.link.zoneSchema
}

defaultFieldSchemas.featured_image = {
    "name": "featured_image",
    "displayName": "Featured Image",
    "type": "image",
}

defaultFieldSchemas.thumbnail = {
    "name": "thumbnail",
    "displayName": "Thumbnail",
    "type": "image",
}

defaultFieldSchemas.featured_video = {
    "name": "featured_video",
    "displayName": "Video",
    "type": "video",
}
defaultFieldSchemas.alignment = {
    "name": "alignment",
    "displayName": "Alignment",
    "type": "choice",
    "options": [{
        "displayName": "Left",
        "value": "left",
    }, {
        "displayName": "Right",
        "value": "right",
    }, {
        "displayName": "Center",
        "value": "center",
    }, {
        "displayName": "Full",
        "value": "full",
    }]
}
defaultFieldSchemas.space_after = {
    "name": "space_after",
    "displayName": "Spacing",
    "type": "choice",
    "options": [{
        "displayName": "Small",
        "value": "sm",
    }, {
        "displayName": "Medium",
        "value": "md",
    }, {
        "displayName": "Large",
        "value": "lg",
    },{
        "displayName": "Extra Large",
        "value": "xl",
    },{
        "displayName": "Tight",
        "value": "null",
    }]
}
defaultFieldSchemas.space_below = {
    "name": "space_below",
    "displayName": "Spacing Below",
    "type": "choice",
    "options": [{
        "displayName": "Small",
        "value": "sm",
    }, {
        "displayName": "Medium",
        "value": "md",
    }, {
        "displayName": "Large",
        "value": "lg",
    },{
        "displayName": "Extra Large",
        "value": "xl",
    },{
        "displayName": "Tight",
        "value": "null",
    }]
}
defaultFieldSchemas.created_at = {
    "name": "created_at",
    "displayName": "Created At",
    "type": "date",
}

defaultFieldSchemas.site_setting = [
    defaultFieldSchemas.title,
    {
        "name": "googleAnalyticsCode",
        "displayName": "Google Analytics Code",
        "type": "text"
    },
    {
        ...defaultFieldSchemas.featured_image,
        "name": "logo",
        "displayName": "Site Logo"
    },
    {
        ...defaultFieldSchemas.featured_image,
        "name": "footerLogo",
        "displayName": "Footer Logo"
    },
    {
        ...defaultFieldSchemas.title,
        "name": "facebook",
        "displayName": "Facebook Link"
    },
    {
        ...defaultFieldSchemas.title,
        "name": "twitter",
        "displayName": "Twitter Link"
    },
    {
        ...defaultFieldSchemas.title,
        "name": "linkedin",
        "displayName": "Linkedin Link"
    },
    {
        ...defaultFieldSchemas.title,
        "name": "instagram",
        "displayName": "Instagram Link"
    },
    {
        ...defaultFieldSchemas.title,
        "name": "mailchimp",
        "displayName": "Mailchimp ID"
    },
    {
        ...defaultFieldSchemas.content,
        "name": "address",
        "displayName": "Address"
    }
]
// const defaultFieldSchemas = {
    
//     content: {},
//     link: {},
//     featured_image: {},
//     tags: {},
//     featured_video: {},
//     alignment: {},
//     space_after: {},
//     }

const moduleSchema = {
    Accordion: [{
        "name": "accordions",
        "displayName": "Accordion Items",
        "type": "repeater",
        "repeaterDisplayKey": "question",
        "repeaterSchema": [
            {
                "name": "question",
                "displayName": "Question",
                "type": "input"
            },
            {
                "name": "answer",
                "displayName": "Answer",
                "type": "richtext"
            }
        ]
    }],
    ButtonLinks: [
        {
            "name": "links",
            "displayName": "Buttons",
            "type": "repeater",
            "repeaterDisplayKey": "title",
            "repeaterSchema": [
                {
                    "name": "thumbnail",
                    "displayName": "Button Icon",
                    "displayDescription": "Make sure your icon is a square",
                    "type": "image"
                },
                defaultFieldSchemas.title,
                {
                    "name": "url",
                    "displayName": "URL",
                    "type": "url"
                },
                {
                    "name": "target",
                    "displayName": "Does this link open in a new window?",
                    "type": "choice",
                    "options": [{
                        "displayName": "Yes. Open in a new window.",
                        "value": "_blank"
                    }, {
                        "displayName": "No. Open in the same window.",
                        "value": ""
                    }]
                }
            ]
        }
    ],
    Banner: [
        {
            "name": "rmDarkBg",
            "displayName": "Remove dark background",
            "type": "choice",
            "options": [{
                "displayName": "yes",
                "value": true,
            },{
                "displayName": "No",
                "value": false,
            }]
        },
        defaultFieldSchemas.title, 
        defaultFieldSchemas.content, 
        defaultFieldSchemas.tags,
        defaultFieldSchemas.link,
        defaultFieldSchemas.featured_image,
        defaultFieldSchemas.featured_video,
        defaultFieldSchemas.alignment,
        defaultFieldSchemas.space_after
    ],
    Callout: [
        defaultFieldSchemas.title,
        defaultFieldSchemas.space_below,
        {
            "name": "type",
            "type": "choice",
            "displayName": "Alignment",
            "options": [{
                "displayName": "Center",
                "value": "center"
            }, {
                "displayName": "Left",
                "value": "left"
            }, {
                "displayName": "Right",
                "value": "right"
            }]
        }
    ],
    ContentImage: [
        defaultFieldSchemas.title, 
        defaultFieldSchemas.featured_image
    ],
    ContentMedia: [
        defaultFieldSchemas.title, 
        defaultFieldSchemas.content, 
        defaultFieldSchemas.featured_image, 
        defaultFieldSchemas.featured_video, 
        defaultFieldSchemas.featured_embed, 
        defaultFieldSchemas.link, 
        defaultFieldSchemas.thumbnail,
        {
            "name": "image_first_mobile",
            "displayName": "Would you like the image to appear first on mobile?",
            "type": "choice",
            "options": [{
                "displayName": "yes",
                "value": true,
            },{
                "displayName": "No",
                "value": false,
            }]
        },
        {
            "name": "flipOrientation",
            "displayName": "Select to Flip Orientation of Media on Desktop",
            "type": "boolean",
        },
        defaultFieldSchemas.space_after
    ],
    ContentSwitcher: [{
        "name": "contents",
        "displayName": "Switchable Content",
        "type": "repeater",
        "repeaterDisplayKey": "title",
        "repeaterSchema": [
            defaultFieldSchemas.title, 
            defaultFieldSchemas.content
        ]

    }],
    DirectoryList: [{
        "name": "floor_plan_image",
        "type": "image",
        "displayName": "Upload your floor plan if you want to show it"
    }],
    Leasing: [{
        "name": "findspaceIds",
        "displayName": "Findspace Building Ids",
        "displayDescription": "Enter comma separated findspace ids for the buildings you want to display",
        "type": "stringarray"
    }],
    Heading: [
        {
            "displayName": "Heading Content",
            "name": "content",
            "type": "text"
        },
        {
            "name": "className",
            "type": "text",
            "displayName": "Custom CSS classes"
        },
        {
            "name": "level",
            "type": "choice",
            "options": [{
                "displayName": "1",
                "value": 1,
            }, {
                "displayName": "2",
                "value": 2,
            }, {
                "displayName": "2",
                "value": 2,
            }, {
                "displayName": "3",
                "value": 3,
            }, {
                "displayName": "4",
                "value": 4,
            }, {
                "displayName": "5",
                "value": 5,
            }]
        },
        {
            "name": "lineEmphasis",
            "displayName": "Emphasize with Line",
            "type": "boolean",
        },
        {
            "name": "blockEmphasis",
            "displayName": "Emphasize with Block",
            "type": "boolean",
        }
    ],
    Hero: [{
            "name": "heroes",
            "displayName": "Heros",
            "type": "repeater",
            "repeaterDisplayKey": "title",
            "repeaterSchema": [
                defaultFieldSchemas.title,
                defaultFieldSchemas.content,
                defaultFieldSchemas.featured_image,
                defaultFieldSchemas.link
            ]
    }, {
        "name": "timer",
        "displayName": "Timer",
        "displayDescription": "Default is 3000",
        "type": "input"
    }, {
        "name": "speed",
        "displayName": "Speed",
        "displayDescription": "Default is 500",
        "type": "input"
    }],
    // Leasing: [],
    IconText: [
        defaultFieldSchemas.title,
        defaultFieldSchemas.content,
        defaultFieldSchemas.thumbnail,
        defaultFieldSchemas.alignment,
        defaultFieldSchemas.space_after
    ],
    NewsListing: [
        defaultFieldSchemas.space_after
    ],
    // PremiseCalendar: [],
    RichText: [
        defaultFieldSchemas.content,
        defaultFieldSchemas.space_after

    ],
    News: [
        defaultFieldSchemas.title,
        defaultFieldSchemas.content,
        defaultFieldSchemas.featured_image,
        defaultFieldSchemas.created_at
    ], 
    Pages: [
        defaultFieldSchemas.title,
        {
            "name": "path",
            "displayName": "URL Slug",
            "type": "input"
        },
        {
            "name": "page_redirect",
            "displayName": "Redirect URL",
            "type": "input"
        },
        defaultFieldSchemas.featured_image,
        {
            "name": "parent",
            "displayName": "Choose Parent",
            "type": "choice",
            "options": [
                {
                    "displayName": "No Parent",
                    "value": ""
                }
                // Do not add any options
                // The options will be automatic add by system
            ] 
        },
        {
            "name": "order",
            "displayName": "Page Order",
            "type": "input"
        }
    ],
    PageProperties: [
        defaultFieldSchemas.title,
        {
            "name": "path",
            "displayName": "URL Slug",
            "type": "input"
        },
        {
            "name": "page_redirect",
            "displayName": "Redirect URL",
            "type": "input"
        },
        {
            "name": "order",
            "displayName": "Page Order",
            "type": "input"
        }
    ],
    PageTitle: [
        defaultFieldSchemas.title, 
        defaultFieldSchemas.featured_image, 
        defaultFieldSchemas.featured_video
    ],
    Units: [
        defaultFieldSchemas.title,
        defaultFieldSchemas.content,
        defaultFieldSchemas.featured_image,
        {
            "name": "floor",
            "displayName": "Floor",
            "type": "input"
        },
        defaultFieldSchemas.link,
        {
            "name": "phone",
            "displayName": "Phone Number",
            "type": "input"
        },
        {
            "name": "status",
            "displayName": "Status of Store",
            "type": "input"
        },
        {
            "name": "suite",
            "displayName": "Suite",
            "type": "input"
        },
        {
            "name": "thumbnail",
            "displayName": "Thumbnail",
            "type": "image",
        },
        {
            "name": "tower",
            "displayName": "Tower",
            "type": "input"
        },
        {
            "name": "type",
            "displayName": "Type",
            "type": "choice",
            "options": [{
                "displayName": "Retail",
                "value": "Retail"
            }, {
                "displayName": "Office",
                "value": "Office"
            }]
        },
        {
            "name": "category",
            "displayName": "Category",
            "type": "input"
        },
        {
            "name": "hours",
            "displayName": "Hour Items",
            "type": "repeater",
            "repeaterDisplayKey": "day",
            "repeaterSchema": [
                {
                    "name": "day",
                    "displayName": "Day",
                    "type": "input"
                },
                {
                    "name": "hours",
                    "displayName": "hours",
                    "type": "input"
                }
            ]
        }
    ],
    Users: [
        {
            "name": 'fname',
            "displayName": "First Name",
            "type": "input"
        },
        {
            "name": 'lname',
            "displayName": "Last Name",
            "type": "input"
        },
        {
            "name": 'email',
            "displayName": "Email",
            "type": "input"
        },
        {
            "name": "phone",
            "displayName": "Phone Number",
            "type": "input"
        },
        {
            "name": "role",
            "displayName": "Role",
            "type": "choice",
            "options": [{
                "displayName": "Group Manager",
                "value": "groupmanager"
            }, {
                "displayName": "Site Manager",
                "value": "sitemanager"
            }, {
                "displayName": "Site Tennant",
                "value": "sitetennant"
            }]
        },
        // {
        //     "name": "sites",
        //     "displayName": "Sites",
        //     "type": "repeater",
        //     "repeaterDisplayKey": "title",
        //     "repeaterSchema": [
        //         defaultFieldSchemas.title,
        //     ]
        // },
        {
            "name": "password",
            "displayName": "Password",
            "type": "input"
        }
    ],
    UserSites: [
        {
            "name": "sites",
            "displayName": "Sites",
            "type": "repeater",
            "repeaterDisplayKey": "title",
            "repeaterSchema": [
                {
                    "name": "_id",
                    "displayName": "Choose Site",
                    "type": "choice",
                    "options": [
                        // Do not add any options
                        // The options will be automatic add by system when user using this schema
                        // Make sure this object stand at the first of options array
                    ] 
                }
            ]
        }
    ],
    Sites: defaultFieldSchemas.site_setting,
    Slider: [
        {
            "name": "images",
            "displayName": "Slider Images",
            "type": "repeater",
            "repeaterDisplayKey": "title",
            "repeaterSchema": [
                defaultFieldSchemas.title,
                defaultFieldSchemas.featured_image
            ]
        },
        {
            "name": "showTitleInModal",
            "displayName": "Show Title In Modal",
            "type": "boolean"
        },
        {
            "name": "centerAlign",
            "displayName": "Align Center",
            "type": "boolean"
        },
        defaultFieldSchemas.space_after
    ],
    SiteSettings: defaultFieldSchemas.site_setting,
    CloneSite: [
        {
            "name": "title",
            "displayName": "Title",
            "type": "input"
        },
        {
            "name": "siteid",
            "displayName": "Which site you want to clone ?",
            "type": "choice",
            "options": [
                // Do not add any options
                // The options will be automatic add by system when user using this schema
                // Make sure this object stand at the second of options array
            ]
        }
    ],
    FlipCardList: [
        {
            "name": "items",
            "displayName": "Card Items",
            "type": "repeater",
            "repeaterDisplayKey": "title",
            "repeaterSchema": [
                defaultFieldSchemas.title,
                defaultFieldSchemas.thumbnail,
                defaultFieldSchemas.content, 
                {
                    "name": "url",
                    "displayName": "URL",
                    "displayDescription": "Leave empty if you want this to not show",
                    "type": "url"
                }
            ]
        },
        defaultFieldSchemas.space_after
    ],
    RelatedLinks: [
        defaultFieldSchemas.title,
        {
            "name": "image_view",
            "displayName": "Image View",
            "type": "choice",
            "options": [{
                "displayName": "Featured Image",
                "value": "featured_image"
            }, {
                "displayName": "Thumbnail",
                "value": "thumbnail"
            }]
        },
        {
            "name": "links",
            "displayName": "Links",
            "type": "repeater",
            "repeaterDisplayKey": "title",
            "repeaterSchema": [
                defaultFieldSchemas.title,
                defaultFieldSchemas.featured_image,
                defaultFieldSchemas.thumbnail,
                {
                    "name": "url",
                    "displayName": "URL",
                    "displayDescription": "Leave empty if you want this to not show",
                    "type": "url"
                }
            ]
        },
        defaultFieldSchemas.space_after

    ]
}

// const componentItems = {
//     NewsItem: [
//         defaultFieldSchemas.title,
//         defaultFieldSchemas.content,
//         defaultFieldSchemas.featured_image
//     ],
//     Page: [

//     ]
// }

const fields = {
    text: 'input',
    richtext: 'tinymce',
    choice: 'select',
    multichoice: 'checkbox',
    repeater: 'repeater',
    image: 'dropzone',
    imagelist: 'dropzone',
    url: 'input',
    boolean: 'select'
}

export default moduleSchema;