import React from 'react';

const Quad = () => {
    return (
        <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
        <polygon id="path-1" points="0.111347999 0.137339998 0.291347997 0.137339998 0.291347997 0.227447997 0.111347999 0.227447997"></polygon>
        <polygon id="path-3" points="0.111347999 0.137339998 0.291347997 0.137339998 0.291347997 0.227447997 0.111347999 0.227447997"></polygon>
        <polygon id="path-5" points="0.111347999 0.137339998 0.291347997 0.137339998 0.291347997 0.227447997 0.111347999 0.227447997"></polygon>
        <polygon id="path-7" points="0.142775998 0.0164699998 6.16265993 0.0164699998 6.16265993 9.38942989 0.142775998 9.38942989"></polygon>
        <polygon id="path-9" points="0.0621899993 0.0412919995 7.94132991 0.0412919995 7.94132991 6.47999992 0.0621899993 6.47999992"></polygon>
        <polygon id="path-11" points="0.111347999 0.089909999 0.291347997 0.089909999 0.291347997 0.179999998 0.111347999 0.179999998"></polygon>
        <polygon id="path-13" points="0.111347999 0.089909999 0.291347997 0.089909999 0.291347997 0.179999998 0.111347999 0.179999998"></polygon>
        <polygon id="path-15" points="0.111347999 0.089909999 0.291347997 0.089909999 0.291347997 0.179999998 0.111347999 0.179999998"></polygon>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="(d)-leasing" transform="translate(-705.000000, -9.000000)">
            <g id="Group-49" transform="translate(705.000000, 9.000000)">
                <path d="M19.3689718,6.61598992 C17.9817118,4.32008995 16.3836718,2.16584997 14.6013118,0.180269998 C11.9697119,3.11210996 9.73933189,6.41150993 7.99711191,9.99224988 C11.5515719,8.3641499 15.3693718,7.21160992 19.3689718,6.61598992" id="Fill-1" fill="#E53E2F"></path>
                <path d="M9.83204989,22.9439517 C11.2196699,25.2409317 12.8184299,27.3960717 14.6013298,29.3825517 C17.2334698,26.4501717 19.4640298,23.1500517 21.2064298,19.5685918 C17.6512498,21.1965118 13.8325498,22.3488717 9.83204989,22.9439517" id="Fill-3" fill="#0DBFDB"></path>
                <path d="M23.1828117,14.7813838 C23.8567317,12.8150639 24.3904317,10.7835839 24.7722117,8.6990039 C24.0390717,7.85948391 23.2735317,7.04894392 22.4773917,6.26936393 C21.4303318,6.34748393 20.3935318,6.46340393 19.3689718,6.61604392 C20.9144518,9.17420389 22.1982117,11.9084039 23.1828117,14.7813838" id="Fill-5" fill="#FED821"></path>
                <path d="M19.3689718,6.61598992 C20.3935318,6.46334993 21.4303318,6.34742993 22.4773917,6.26930993 C20.1057118,3.94676995 17.4631318,1.89980998 14.6014918,0.180089998 L14.6013118,0.180269998 C16.3836718,2.16584997 17.9817118,4.32008995 19.3689718,6.61598992" id="Fill-7" fill="#CE202E"></path>
                <path d="M5.03963994,11.4897059 C5.32295994,12.6051659 5.65037993,13.7029858 6.01991993,14.7813658 C6.58331992,13.1372458 7.24463992,11.5384859 7.99703991,9.99228588 C6.98957992,10.4538059 6.00299993,10.9534859 5.03963994,11.4897059" id="Fill-9" fill="#9ACAE9"></path>
                <path d="M14.6013478,0.180197998 L14.6013478,0.180197998 C10.7790479,2.47699797 7.34770791,5.35789794 4.43044795,8.6990579 C4.60288795,9.64045789 4.80628794,10.5710579 5.03956794,11.4897779 C6.00292793,10.9535579 6.98950792,10.4538779 7.99714791,9.99235788 C9.73936789,6.41161793 11.9697479,3.11203796 14.6013478,0.180197998" id="Fill-11" fill="#A8333A"></path>
                <g id="Group-15" transform="translate(14.400000, 0.042660)">
                    <mask id="mask-2" fill="white">
                        <use xlinkHref="#path-1"></use>
                    </mask>
                    <g id="Clip-14"></g>
                    <polygon id="Fill-13" fill="#E53D2F" mask="url(#mask-2)" points="0.201257998 0.137447998 0.201257998 0.137447998 0.201437998 0.137447998"></polygon>
                </g>
                <g id="Group-18" transform="translate(14.400000, 0.042660)">
                    <mask id="mask-4" fill="white">
                        <use xlinkHref="#path-3"></use>
                    </mask>
                    <g id="Clip-17"></g>
                    <polygon id="Fill-16" fill="#FEDA00" mask="url(#mask-4)" points="0.201257998 0.137447998 0.201257998 0.137447998 0.201437998 0.137447998"></polygon>
                </g>
                <g id="Group-21" transform="translate(14.400000, 0.042660)">
                    <mask id="mask-6" fill="white">
                        <use xlinkHref="#path-5"></use>
                    </mask>
                    <g id="Clip-20"></g>
                    <polygon id="Fill-19" fill="#FEDA00" mask="url(#mask-6)" points="0.201257998 0.137447998 0.201257998 0.137447998 0.201437998 0.137447998"></polygon>
                </g>
                <path d="M24.1628757,18.0720898 C23.8795557,16.9569898 23.5521357,15.8593498 23.1827757,14.7813298 C22.6195557,16.4247298 21.9584157,18.0229498 21.2065558,19.5684298 C22.2136557,19.1072698 23.1996957,18.6079498 24.1628757,18.0720898" id="Fill-22" fill="#9ACAE9"></path>
                <path d="M21.2065018,19.5685198 C19.4641018,23.1499797 17.2335418,26.4500997 14.6014018,29.3824797 L14.6014018,29.3824797 C18.4237018,27.0856797 21.8550417,24.2047797 24.7723017,20.8636198 C24.5998617,19.9218598 24.3962817,18.9910798 24.1628217,18.0721798 C23.1998217,18.6078598 22.2137817,19.1071798 21.2065018,19.5685198" id="Fill-24" fill="#00ACD7"></path>
                <g id="Group-28" transform="translate(23.040000, 8.682660)">
                    <mask id="mask-8" fill="white">
                        <use xlinkHref="#path-7"></use>
                    </mask>
                    <g id="Clip-27"></g>
                    <path d="M1.12287599,9.38942989 C2.88327597,8.4102299 4.56699595,7.30934992 6.16269593,6.09866993 C4.86489594,3.93902995 3.38061596,1.90430998 1.73217598,0.0164699998 C1.35039598,2.10086998 0.816695991,4.13234995 0.142775998,6.09866993 C0.512135994,7.17668992 0.83955599,8.2743299 1.12287599,9.38942989" id="Fill-26" fill="#ABAC36" mask="url(#mask-8)"></path>
                </g>
                <path d="M24.7722297,20.8635838 C26.4206697,18.9757438 27.9047697,16.9410238 29.2025697,14.7813838 L29.2025697,14.7812038 C27.6070497,15.9920638 25.9233297,17.0927638 24.1629297,18.0721438 C24.3963897,18.9910438 24.5997897,19.9220038 24.7722297,20.8635838" id="Fill-29" fill="#0081A2"></path>
                <path d="M6.01988393,14.7813838 C5.34596394,16.7477038 4.81226394,18.7790038 4.43030395,20.8635838 C5.16254394,21.7022037 5.92718393,22.5116637 6.72206392,23.2901637 C7.76984391,23.2124037 8.8068239,23.0964837 9.83210389,22.9440237 C8.2873439,20.3865838 7.00412392,17.6532838 6.01988393,14.7813838" id="Fill-31" fill="#96C93C"></path>
                <g id="Group-35" transform="translate(6.660000, 22.902660)">
                    <mask id="mask-10" fill="white">
                        <use xlinkHref="#path-9"></use>
                    </mask>
                    <g id="Clip-34"></g>
                    <path d="M3.17204996,0.0412919995 C2.14694998,0.193751998 1.10978999,0.309671996 0.0621899993,0.387611996 C2.43476997,2.71123197 5.07824994,4.75945194 7.94132991,6.48007192 L7.94132991,6.47989192 C6.15842993,4.49341195 4.55966995,2.33827197 3.17204996,0.0412919995" id="Fill-33" fill="#00954B" mask="url(#mask-10)"></path>
                </g>
                <path d="M5.03963994,11.4897059 C3.27905996,12.4692659 1.59551998,13.5703258 0,14.7813658 C1.29779998,16.9410058 2.78207997,18.9757258 4.43033995,20.8635658 C4.81211994,18.7791658 5.34599994,16.7476858 6.01991993,14.7813658 C5.65037993,13.7029858 5.32295994,12.6051659 5.03963994,11.4897059" id="Fill-36" fill="#61A644"></path>
                <path d="M4.43037595,8.6990939 C2.78211597,10.5869339 1.29783598,12.6216539 3.59999996e-05,14.7812938 L3.59999996e-05,14.7814738 C1.59555598,13.5702538 3.27909596,12.4691939 5.03967594,11.4896339 C4.80621594,10.5710939 4.60281595,9.64049389 4.43037595,8.6990939" id="Fill-38" fill="#79222E"></path>
                <g id="Group-42" transform="translate(14.400000, 29.202660)">
                    <mask id="mask-12" fill="white">
                        <use xlinkHref="#path-11"></use>
                    </mask>
                    <g id="Clip-41"></g>
                    <polygon id="Fill-40" fill="#E53D2F" mask="url(#mask-12)" points="0.201437998 0.179999998 0.201437998 0.179819998 0.201257998 0.179999998"></polygon>
                </g>
                <g id="Group-45" transform="translate(14.400000, 29.202660)">
                    <mask id="mask-14" fill="white">
                        <use xlinkHref="#path-13"></use>
                    </mask>
                    <g id="Clip-44"></g>
                    <polygon id="Fill-43" fill="#FEDA00" mask="url(#mask-14)" points="0.201437998 0.179999998 0.201437998 0.179819998 0.201257998 0.179999998"></polygon>
                </g>
                <g id="Group-48" transform="translate(14.400000, 29.202660)">
                    <mask id="mask-16" fill="white">
                        <use xlinkHref="#path-15"></use>
                    </mask>
                    <g id="Clip-47"></g>
                    <polygon id="Fill-46" fill="#FEDA00" mask="url(#mask-16)" points="0.201437998 0.179999998 0.201437998 0.179819998 0.201257998 0.179999998"></polygon>
                </g>
            </g>
        </g>
    </g>
</svg>
    )
}

export default Quad;