import React, {useState} from 'react';
import LeasingAccordionItem from './LeasingAccordionItem';
import PlusMinus from './../../icons/PlusMinus';

import './LeasingAccordion.scss';

const LeasingAccordion = ({suites}) => {
    const [showDetails, setShowDetails] = useState(false);
    const toggleDetails = () => {
        setShowDetails(!showDetails);
    }

    return(<div className="leasing-accordion sp-m-md">
        {suites.length >0 && <div onClick={toggleDetails} className="header bg-foreground sp-p-md">
            <h3 className="c-background fs-md container">{suites.length} Suites Available 
                <div className="leasingbutton"><PlusMinus val={!showDetails}/></div>
            </h3>
        </div>}
        {!!showDetails && <ul className="accordion-contents">
            {suites.map((suite) => {
                return <LeasingAccordionItem suite={suite}/>
            })}
        </ul>}
    </div>)
}

export default LeasingAccordion;